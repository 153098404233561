import React, {useEffect, useState} from 'react';
import Personal from "./Personal";
import Medications from "./Medications";
import Visits from "./Visits";
import DoctorComments from "./DoctorComments";
import Attachments from "./Attachments";
import Probes from "./Probes";
import Messages from "./Messages";
import Sets from "./Sets";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, makeStyles} from "@material-ui/core";
import * as Actions from "../../store/actions/CardAction";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingBottom: theme.spacing(10),
        },
    },
    expandedRoot: {
        paddingLeft: 423,
    },
    printPDF: {
        padding: theme.spacing(4),
    },
    cardContainer: {
        paddingTop: theme.spacing(16),
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& > .MuiCard-root": {
            width: "100%",
            minHeight: "100vh",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(4),
            "& .MuiCard-root": {
                width: "100%",
                maxWidth: 1017,
            },
        },
    },
    card: {
        boxShadow: "none",
        [theme.breakpoints.up("lg")]: {
            border: "1px solid #DDE3EE",
            borderRadius: 8,
        },
    },
    cardHeader: {
        padding: theme.spacing(2),
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #DDE3EE",
        "& > .MuiBox-root:first-child": {
            display: "none",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(6),
            display: "flex",
            "& > .MuiBox-root:first-child": {
                display: "block",
            },
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 32,
        color: "#003366",
        letterSpacing: "-2px",
        marginBottom: theme.spacing(2)
    },
    pesel: {
        fontFamily: "Lato",
        fontSize: 20,
        color: "#6C7689",
    },
    toolbar: {
        display: "flex",
        flexDirection: "row",
        "& > .MuiBox-root": {
            display: "flex",
        },
        [theme.breakpoints.down("1650")]: {
            flexDirection: "column",
            "& > .MuiBox-root": {
                "&:first-child": {
                    marginBottom: theme.spacing(2),
                    justifyContent: "space-between",
                    "& .MuiButton-root:last-child": {
                        marginRight: 0,
                    },
                },
                "&:last-child": {
                    "& .MuiButton-root": {
                        width: "100%",
                    },
                },
            },
        },
    },
    printButton: {
        width: 60,
        minWidth: 60,
        background: "#6C7689!important",
        borderRadius: 8,
        marginRight: 16,
    },
    deleteButton: {
        width: 60,
        minWidth: 60,
        height: 56,
        borderRadius: 8,
        background: "#D26360",
        "&:hover": {
            background: "#D26360",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2),
        "& img": {
            filter: "brightness(0) invert(1)",
        },
    },
    editButton: {
        width: 176,
        height: 56,
        borderRadius: 8,
        textTransform: "none",
        border: "2px solid #003366",
        background: "#FFF",
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#003366",
        marginRight: theme.spacing(3),
        [theme.breakpoints.down("1650")]: {
            width: 130
        }
    },
    appointmentButton: {
        width: 178,
        height: 56,
        textTransform: "none",
        borderRadius: 8,
        background: "#003366",
        "&:hover": {
            background: "#003366",
        },
        "& .MuiTypography-root": {
            marginLeft: theme.spacing(2),
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#FFF",
        },
    },
    cardBody: {
        padding: theme.spacing(0, 2, 3),
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(2, 6, 8, 6),
        },
    },
    tab: {
        height: 56,
        padding: "0 20px",
        fontFamily: "Lato",
        fontSize: 16,
        color: "#6C7689",
        textTransform: "none",
        letterSpacing: "-1px",
        borderRadius: 8,
        display: "none",
        [theme.breakpoints.up("lg")]: {
            display: "inline-flex",
        },
    },
    activeTab: {
        background: "#E5F2FF",
        fontWeight: 700,
        color: "#0066CC",
    },
    removeModal: {
        "& .MuiPaper-root": {
            width: 600,
            padding: theme.spacing(3, 4, 4),
            "& .MuiBox-root": {
                "&:first-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                },
                "&:last-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                },
            },
            "& .MuiTypography-root": {
                padding: theme.spacing(5, 0),
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 16,
                color: "#454D5F",
            },
        },
    },
    yesBtn: {
        width: 140,
        height: 56,
        background: "#D26360",
        borderRadius: 8,
        color: "#FFF",
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
        marginLeft: theme.spacing(3),
        "&:hover": {
            background: "#D26360",
        },
    },
    noBtn: {
        width: 140,
        height: 56,
        background: "#FFF",
        border: "1px solid #003366",
        borderRadius: 8,
        color: "#003366",
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
    },
    collapseSidebar: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        minWidth: 20,
        padding: 5,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    }
}));

const MAPPED_TABS = {
    "personal": <Personal/>,
    "medications": <Medications/>,
    "visits": <Visits/>,
    "comments": <DoctorComments/>,
    "attachments": <Attachments/>,
    "samples": <Probes/>,
    "messages": <Messages/>,
    "sets": <Sets/>,
};

function PatientCardTabLayout() {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {selectedPatient, selectedPatientID, selectedTab, selectedSubTab} = useSelector((state) => state.card);
    const tabs = [
        {
            id: "personal",
            name: t("pages.patient.layout.personalMedicalData"),
        },
        {
            id: "medications",
            name: t("pages.patient.layout.medicationTaken"),
        },
        {
            id: "visits",
            name: t("pages.patient.layout.visits"),
        },
        {
            id: "comments",
            name: t("pages.patient.layout.doctorComments"),
        },
        {
            id: "attachments",
            name: t("pages.patient.layout.attachments"),
        },
        {
            id: "samples",
            name: t("pages.patient.layout.samples"),
        },
        {
            id: "messages",
            name: t("pages.patient.layout.messages"),
        },
        {
            id: "sets",
            name: t("pages.patient.layout.sets"),
        },
    ];

    useEffect(() => {

        if (selectedTab == null)
        {
            handleSelectTab(tabs[0]?.id);
        }

        //
    }, [selectedPatient]);

    const handleSelectTab = (data) => {
       // if (selectedTab === data && selectedSubTab?.toggle) {
     //       dispatch(Actions.setSubTab({...selectedSubTab, toggle: false}));
       // }
        dispatch(Actions.setSubTab({...selectedSubTab, toggle: false}));
        dispatch(Actions.setSelectedTab(data));
    };

    return (
        <Box className={classes.cardBody}>
            <Box>
                {Object.keys(tabs).map((key) => {
                    const tab = tabs[key];

                    return (
                        <Button
                            className={`${classes.tab} ${
                                selectedTab === tab.id ? classes.activeTab : ``
                            }`}
                            onClick={() => handleSelectTab(tab?.id)}
                            key={key}
                        >
                            {tab.name}
                        </Button>
                    );
                })}
            </Box>
            {MAPPED_TABS[selectedTab]}
        </Box>
    )
}

export default PatientCardTabLayout;
