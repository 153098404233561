import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Routes from "./Routes";
import Admin from "layouts/Admin";

import Login from "pages/Auth/Login";
import Documents from "pages/Documents/Documents";
import ChangePassword from "pages/ChangePassword";
import PatientCard from "pages/PatientCard/PatientCard";
import PatientList from "pages/PatientList/List";
import CreatePatient from "pages/PatientCard/Create";
import UpdatePatient from "pages/PatientCard/Update";
import Calendar from "pages/Calednar/Calendar";

const AdminLayoutRoutes = [
  {
    component: PatientList,
    path: Routes.Dashboard.PatientList,
  },
  {
    component: CreatePatient,
    path: Routes.Dashboard.CreatePatient,
  },
  {
    component: UpdatePatient,
    path: Routes.Dashboard.UpdatePatient,
  },
  {
    component: Calendar,
    path: Routes.Dashboard.Calendar,
  },
  {
    component: PatientCard,
    path: Routes.Dashboard.PatientCard,
  },
  {
    component: Documents,
    title: "Dane osobowe i medyczne",
    path: Routes.Dashboard.Documents,
  },
  {
    component: ChangePassword,
    title: "Dane osobowe i medyczne",
    path: Routes.Dashboard.ChangePassword,
  },
];

const AdminLayout = () => {
  return (
    <Switch>
      <Admin>
        {AdminLayoutRoutes.map((route, index) => {
          return <Route key={index} exact {...route} />;
        })}
      </Admin>
    </Switch>
  );
};

const WithoutLayoutRoutes = [
  {
    component: Login,
    title: "Login",
    path: Routes.Auth.Login,
  },
];

const WithoutLayout = () => {
  return (
    <Switch>
      {WithoutLayoutRoutes.map((route, index) => {
        return <Route key={index} exact {...route} />;
      })}
    </Switch>
  );
};

const RoutesRouter = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={Routes.Auth.Login} />
      <Route
        path={AdminLayoutRoutes.map((route) => route.path)}
        exact
        component={AdminLayout}
      />
      <Route
        path={WithoutLayoutRoutes.map((route) => route.path)}
        exact
        component={WithoutLayout}
      />
      <Redirect to={Routes.Auth.Login} />
    </Switch>
  );
};

export default RoutesRouter;
