import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Radio,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import TextInput from "components/form/TextInput";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 24,
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "8px 40px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiFormControlLabel-root": {
      fontSize: 16,
    },
  },
  checkbox: {
    margin: "0!important",
    "& .MuiButtonBase-root": {
      padding: "0 16px 0 0!important",
    },
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: 700,
      color: "#22272F",
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    marginRight: 24,
  },
}));

const MedicineTicket = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [dose, setDose] = useState("");
  const [customDose, setCustomDose] = useState(0);

  const handleChangeCustomDose = (e, medicineID) => {
    setCustomDose(e.target.value);
    props.handleChangeDose(medicineID, e.target.value * 1);
  };

  const handleChange = (e, dose) => {
    setChecked(!checked);
    setDose(String(dose));
    props.handleChangeMedicine(e.target.checked, e.target.value * 1);
    if (!checked) {
      props.handleChangeDose(e.target.value * 1, dose);
    }
  };

  const handleChangeDose = (e, medicineID) => {
    setDose(e.target.value);
    props.handleChangeDose(medicineID, e.target.value * 1 || 0);
  };

  const handleFocus = (e) => {
    setDose("custom");
  };

  useEffect(() => {
    if (props.dose) {
      setChecked(props.dose ? true : false);
      if (props.doses.includes(props.dose)) {
        setDose(String(props.dose));
      } else {
        setDose("custom");
        setCustomDose(props.dose);
      }
    }
  }, [props]);

  return (
    <Box className={classes.root}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            color="primary"
            checked={checked}
            value={props.id}
            onChange={(e) => handleChange(e, props.doses[0])}
          />
        }
        label={props.name}
      />
      {checked && (
        <Box className={classes.body}>
          <Typography className={classes.label}>Dawka:</Typography>
          {props.doses.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    color="primary"
                    checked={dose === String(item)}
                    value={item}
                    onChange={(e) => handleChangeDose(e, props.id)}
                  />
                }
                label={`${item} mg`}
              />
            );
          })}
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={dose === "custom"}
                value="custom"
                onChange={(e) => handleChangeDose(e, props.id)}
              />
            }
            label={
              <TextInput
                type="number"
                placeholder="Wartość"
                onFocus={handleFocus}
                value={customDose}
                onChange={(e) => handleChangeCustomDose(e, props.id)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mg</InputAdornment>
                  ),
                }}
              />
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default MedicineTicket;
