import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import * as Actions from "store/actions/CardAction";
import PlusIcon from "assets/images/icons/plus.svg";
import CreateModal from "components/modals/CreateModal";
import PrimaryButton from "components/buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
      color: "#454D5F",
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    border: '1px solid #DDE3EE',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  text: {
    color: "#003366",
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    lineHeight: 2,
  },
  buttonLabel: {
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    textTransform: 'none',
  },
  outlinedButton: {
    color: "#003366",
    background: "#fff",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: "2px solid #003366",
  },
  plusIcon: {
    marginRight: 10,
    filter: "brightness(100) invert(0)",
  },
}));

const Text = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.text}>{children}</Typography>;
};

const ButtonLabel = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.buttonLabel}>{children}</Typography>;
};

const List = ({ onShowDetail }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedPatient } = useSelector((state) => state.card);
  const [openModal, setOpenModal] = useState(false);

  const handleShowDetail = id => () => {
    dispatch(Actions.selectSet(id)).then(() => {
      onShowDetail();
    });
  }

  const getPurchaserName = set => {
    let purchaser = set.doctor || set.patient;
    return `${purchaser.firstname} ${purchaser.surname}`;
  };

  return (
    <Box>
      <Typography className={classes.title}>
        {t("pages.patient.sets.title")}
      </Typography>
      {selectedPatient?.sets?.map((set, i) => (
        <Box key={i} className={classes.card}>
          <Box>
            <Text>{t('pages.patient.sets.purchaser')}: {getPurchaserName(set)}</Text>
            <Text>{t('pages.patient.sets.patient')}: {set.patient.firstname} {set.patient.surname}</Text>
            <Text>{t('pages.patient.sets.createdAt')}: {moment(set.created_at).format('DD.MM.YYYY, HH:ss')}</Text>
          </Box>
          <Box display="flex">
            <PrimaryButton onClick={handleShowDetail(set.id)}>
              <ButtonLabel>{t('pages.patient.sets.detail')}</ButtonLabel>
            </PrimaryButton>
          </Box>
        </Box>
      ))}
      <Box display="flex" justifyContent="flex-end">
        <PrimaryButton onClick={() => setOpenModal(true)}>
          <img src={PlusIcon} alt={t('pages.patient.sets.add')} className={classes.plusIcon} />
          <ButtonLabel>{t('pages.patient.sets.add')}</ButtonLabel>
        </PrimaryButton>
      </Box>
      <CreateModal open={openModal} onClose={() => setOpenModal(false)} />
    </Box>
  );
};

export default List;
