import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import API from "apis/API";
import CloseIcon from "assets/images/icons/close.svg";
import TextInput from "components/form/TextInput";
import Switch from "components/form/Switch";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiPaper-root": {
            width: 477,
        },
        "& .MuiGrid-item": {
            marginBottom: theme.spacing(1),
        },
    },
    dialogHeader: {
        padding: "20px 32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 20,
            color: "#003366",
        },
    },
    closeButton: {
        width: 32,
        height: 32,
        minWidth: 32,
        border: "1px solid #DDE3EE",
        borderRadius: 8,
    },
    dialogBody: {
        padding: "20px 32px 0",
    },
    dialogFooter: {
        padding: "0 32px 40px",
        "& .MuiButton-root": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 56,
            fontWeight: 700,
            fontWize: 16,
            textTransform: "none",
            borderRadius: 8,
            color: "#003366",
            border: "1px solid #A6AEBF",
        },
    },
    saveButton: {
        background: "#003366",
        color: "#FFF!important",
        border: "none!important",
        "&:hover": {
            background: "#003366",
        },
    },
    groupTitle: {
        fontWeight: 700,
        fontSize: 16,
        color: "#454D5F",
        marginBottom: 16,
    },
}));

const FormModal = ({open, onClose, selectedSet, ...rest}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const testTypes = [
        {id: "biochemistry", name: "Biochemia",},
        {id: "drug-concentration", name: "Stężenie leków",},
    ];
    const materialTypes = [
        {id: "venous", name: "Żylna",},
        {id: "capillary", name: "Włośniczkowa",},
    ];
    const purposes = [
        {value: 'home', label: 'Do samodzielnego pobrania w domu'},
        {value: 'clinic', label: 'Do wykorzystania na wizycie'},
    ];

    const {selectedPatient} = useSelector(state => state.card, shallowEqual);
    const set = selectedSet;
    const [formData, setFormData] = useState({
        type_of_material: 'venous',
        collect_place: 'home',
        patient_id: selectedPatient.id,
    });

    const [code, setCode] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!open) return;
        API.card.probe.generateCode(formData).then(res => {
            if (res.data?.code !== '') {
                setCode(res.data.code);
            }
        });
    }, [formData, open]);

    const handleChangeSwitch = (name, value) => {
        setFormData(prev => ({...prev, [name]: value}));

        if (name == 'type_of_material') {
            setFormData(prev => ({...prev, 'collect_place': value == 'venous' ? 'clinic' : 'home'}));
        }
    };

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSave = () => {
        setIsSaving(true);

        formData.set_id = selectedSet.id;

        API.card.probe.create(formData).then((res) => {
            setIsSaving(false);
            if (res.data.status === 'error') {
                return;
            }

            onClose(res.data.probe);
        });
    };

    return (
        <Dialog onClose={onClose} className={classes.dialog} open={open}>
            <Box className={classes.dialogHeader}>
                <Typography>{t('pages.patient.sets.addNewProbe')}</Typography>
                <Button onClick={onClose} className={classes.closeButton}>
                    <img src={CloseIcon} alt="Close"/>
                </Button>
            </Box>
            <Box className={classes.dialogBody}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={classes.groupTitle}>
                            Rodzaj materiału
                        </Typography>
                        <Switch
                            items={materialTypes}
                            onChange={val => handleChangeSwitch("type_of_material", val)}
                        />
                    </Grid>
                    {formData.type_of_material != 'venous' && <Grid item xs={12}>
                        <Typography className={classes.groupTitle}>
                            {t('pages.patient.sets.purpose')}
                        </Typography>
                        <Select
                            value={formData.collect_place}
                            fullWidth
                            name="collect_place"
                            onChange={handleChange}
                            variant="outlined"
                        >
                            {purposes.map((purpose, i) => {
                                return (
                                    <MenuItem key={i} value={purpose.value}>
                                        {purpose.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography className={classes.groupTitle}>
                            Wygenerowany kod próbki
                        </Typography>
                        <TextInput value={code} disabled/>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.dialogFooter}>
                <Grid container spacing={3}>
                    <Grid xs={6} item>
                        <Button onClick={onClose}>{t('common.cancel')}</Button>
                    </Grid>
                    <Grid xs={6} item>
                        <Button className={classes.saveButton} onClick={handleSave}>
                            {isSaving ? (
                                <CircularProgress size={30} style={{color: "#FFF"}}/>
                            ) : (
                                <>{t('common.add')}</>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default FormModal;
