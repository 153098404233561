const Routes = {
  Auth: {
    Login: "/login",
  },
  Dashboard: {
    PatientList: "/patient/list",
    CreatePatient: "/patient/create",
    UpdatePatient: "/patient/update",
    Calendar: "/patient/calendar",
    Account: "/patient/account",
    PatientCard: "/patient/card",
    Documents: "/documents",
    ChangePassword: "/changepassword",
  },
};

export default Routes;
