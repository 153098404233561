import React from "react";
import VisitsTab from 'components/patientParts/Visits';

const Visits = () => {
  return (
    <VisitsTab />
  );
};

export default Visits;
