import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
	primaryButton: {
		color: "#FFF",
		background: "#003366",
		padding: theme.spacing(2, 5),
		borderRadius: theme.spacing(1),
		"&:hover": {
			background: "#003366",
		},
	},
}));

const PrimaryButton = React.forwardRef(({ children, className, ...rest }, ref) => {
	const classes = useStyles();
	return (
		<Button ref={ref} className={clsx(classes.primaryButton, className)} {...rest}>
			{children}
		</Button>
	);
});

export default PrimaryButton;
