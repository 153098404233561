import React, { useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    borderRadius: 8,
    border: "1px solid #DDE3EE",
  },
  tabButton: {
    width: "50%",
    height: 56,
    borderRadius: 8,
    fontFamily: "Lato",
    fontSize: 16,
    color: "#6C7689",
    textTransform: "none",
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      padding: "0 32px",
    },
  },
  selectedTabButton: {
    background: "#E5F2FF",
    fontWeight: 700,
    color: "#0066CC",
  },
}));

const Switch = (props) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(props.items[0].id);
  const handleChangeTab = (tabID) => {
    setSelectedTab(tabID);
    props.onChange(tabID);
  };

  return (
    <Box className={classes.root}>
      {props.items.map((tab) => {
        return (
          <Button
            key={tab.id}
            className={`${classes.tabButton} ${
              tab.id === selectedTab ? classes.selectedTabButton : ``
            }`}
            onClick={() => handleChangeTab(tab.id)}
          >
            {tab.name}
          </Button>
        );
      })}
    </Box>
  );
};

export default Switch;
