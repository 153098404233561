import React, { useState } from "react";

import Detail from "./Detail";
import List from "./List";
import ToggleContainer from "../../patientCardTabs/ToggleContainer";

const Sets = () => {
  return (
      <ToggleContainer Detail={Detail} List={List}/>
  );
};

export default Sets;
