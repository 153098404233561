const initial = {
	erorr: null,
	isLoading: false,
	isTabLoading: false,
	list: [],
	selectedPatient: null,
	selectedPatientID: null,
	visitsBySelectedDate: null,
	visitCount: null,
	carrierList: [],
	selectedTab: null,
	selectedVisit: null,
	selectedSetId: null,
	messageList: [],
};

const CardReducer = (state = initial, action) => {
	switch (action.type) {
		case "GET_PATIENT_LIST":
			return {
				...state,
				list: action.payload,
			};
		case "SET_SELECTED_PATIENT_ID":
			return {
				...state,
				selectedPatientID: action.payload
			};
		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "SET_TAB_LOADING":
			return {
				...state,
				isTabLoading: action.payload,
			};
		case "SET_ERROR":
			return {
				...state,
				erorr: action.payload,
			};
		case "GET_SELECTED_PATIENT_DATA":
			return {
				...state,
				selectedPatient: {
          ...action.payload,
          probes: state.selectedPatient?.probes,
          visits: state.selectedPatient?.visits,
          past_visits: state.selectedPatient?.past_visits,
          future_visits: state.selectedPatient?.future_visits,
        },
			};
		case "GET_SELECTED_PATIENT_PROBES":
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					probes: action.payload.probes
				},
			};
		case "GET_SELECTED_PATIENT_VISITS":
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					visits: action.payload.visits,
					past_visits: action.payload.past_visits,
					future_visits: action.payload.future_visits,
				},
			};
		case "REMOVE_PATIENT_DATA":
			const clonedPatientList = JSON.parse(JSON.stringify(state.list.data));
			let selectedIndex = null;
			clonedPatientList.map((item, index) => {
				if (item.id === action.payload) {
					selectedIndex = index;
				}
			});
			clonedPatientList.splice(selectedIndex, 1);
			return {
				...state,
				selectedPatient: null,
				selectedPatientID: null,
				list: {
					total_count: state.list.total_count - 1,
					data: clonedPatientList,
				},
			};
		case "REMOVE_SELECTED_PATIENT_DATA":
			return {
				...state,
				selectedPatient: null,
				selectedPatientID: null,
			};
		case "ADD_ATTACHMENT_FILE":
			let attachmentFiles = state.selectedPatient.files;
			attachmentFiles.push(action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					files: attachmentFiles,
				},
			};
		case "REMOVE_ATTACHMENT_FILE":
			let files = state.selectedPatient.files;
			let index;
			files.map((file, fIndex) => {
				if (file.id === action.payload) {
					index = fIndex;
				}
			});
			files.splice(index, 1);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					files: files,
				},
			};
		case "UPDATE_DOCTOR_NOTE":
			let notes = state.selectedPatient.notes;
			notes.push(action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					notes,
				},
			};
		case "DELETE_DOCTOR_NOTE":
			let doctorNotes = state.selectedPatient.notes;
			let ind;
			doctorNotes.map((note, nIndex) => {
				if (note.id === action.payload) {
					ind = nIndex;
				}
			});
			doctorNotes.splice(ind, 1);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					notes: doctorNotes,
				},
			};
		case "GET_VISITS_BY_SELECTED_DATE":
			return {
				...state,
				visitsBySelectedDate: action.payload,
			};
		case "DELETE_FUTURE_VISIT":
			const futureVisits = state.selectedPatient.future_visits;
			let visitIndex;
			futureVisits.map((visit, vIndex) => {
				if (visit.id === action.payload) {
					visitIndex = vIndex;
				}
			});
			futureVisits.splice(visitIndex, 1);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					future_visits: futureVisits,
				},
			};
		case "DELETE_VISIT":
			const visits = state.selectedPatient.visits;
			let deleteVisitIndex;


			visits.map((visit, vIndex) => {
				if (visit.id === action.payload) {
					deleteVisitIndex = vIndex;
				}
			});

			visits.splice(deleteVisitIndex, 1);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					visits: visits,
				},
			};
		case "GET_VISIT_COUNT":
			return {
				...state,
				visitCount: action.payload,
			};
		case "ADD_FUTURE_VISITS":
			let fVisits = state.selectedPatient.future_visits;
			fVisits.push(action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					future_visits: fVisits,
				},
			};
		case "UPDATE_FUTURE_VISITS":
			let uFutureVisits = state.selectedPatient.future_visits;
			let ufIndex;
			uFutureVisits.map((visit, index) => {
				if (visit.id === action.payload.id) {
					ufIndex = index;
				}
			});
			uFutureVisits.splice(ufIndex, 1, action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					future_visits: uFutureVisits,
				},
			};
		case "UPDATE_VISITS":
			let uVisits = state.selectedPatient.visits;

			let uvIndex;
			uVisits.map((visit, index) => {
				if (visit.id === action.payload.id) {
					uvIndex = index;
				}
			});
			uVisits.splice(uvIndex, 1, action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					visits: uVisits,
				},
			};
		case "ADD_VISITS":
			let tVisits = state.selectedPatient.visits;
			tVisits.push(action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					visits: tVisits,
				},
			};
		case "GET_CARRIER_LIST":
			return {
				...state,
				carrierList: action.payload,
			};
		case "UPDATE_PROBES":
			let probes = state.selectedPatient.probes;
			probes.push(action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					probes,
				},
			};
		case "SET_SELECTED_SUB_TAB":
			return {
				...state,
				selectedSubTab: action.payload,
			};
		case "SET_SELECTED_TAB":
			return {
				...state,
				selectedTab: action.payload,
			};
		case "DELETE_SELECTED_TAB":
			return {
				...state,
				selectedTab: null,
			};
		case "SELECT_VISIT":
			return {
				...state,
				selectedVisit: action.payload,
			};
		case "DELETE_SELECTED_VISIT":
			return {
				...state,
				selectedVisit: null,
			};
		case "MARK_VISIT_AS_PAST":
			return {
				...state,
				selectedVisit: null,
			};
		case "ADD_SET":
			let sets = state.selectedPatient.sets;
			sets.push(action.payload);
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					sets,
				},
			};
		case "SELECT_SET":
			return {
				...state,
				selectedSetId: action.payload
			};
		case "SELECT_PROBE":
			return {
				...state,
				selectedProbeId: action.payload
			};
		case "GET_MESSAGES_LIST":
			return {
				...state,
				messageList: action.payload,
			};
		case "SEND_MESSAGE":
			let messages = [...state.messageList];

			messages.unshift(action.payload);

			return {
				...state,
				messageList: [...messages],
			};
		case "EDIT_MESSAGE":
			let oldMessages = [...state.messageList];

			oldMessages.map((item, index) => {
				if (item.id === action.payload.id) {
				oldMessages[index] = {...action.payload};
				}
			});

			return {
				...state,
				messageList: oldMessages,
			};
		case "DELETE_MESSAGE":
			let oldMessagesList = [...state.messageList];

			oldMessagesList.map((item, index) => {
				if (item.id === action.payload.id) {
					oldMessagesList.splice(index, 1);
				}
			});

			return {
				...state,
				messageList: oldMessagesList,
			};
		default:
			return state;
	}
};
export default CardReducer;
