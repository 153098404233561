import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useToasts } from "react-toast-notifications";
import Ticket from "components/pagesParts/Create/MedicineTicket";
import PlusIcon from "assets/images/icons/plus-dark.svg";
import AdditionalMedicineForm from "./AdditionalMedicineForm";
import * as Actions from "store/actions/PatientAction";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginBottom: 40,
  },
  formGroupTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#454D5F",
    marginBottom: 24,
  },
  addButton: {
    height: 56,
    padding: "16px 24px",
    background: "#FFFFFF",
    border: "1px solid #A6AEBF",
    borderRadius: 8,
    textTransform: "none",
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: 700,
      color: "#003366",
      marginLeft: 16,
    },
  },
  formFooter: {
    "& .MuiButton-root": {
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      fontWeight: 700,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  nextButton: {
    background: "#003366",
    color: "#FFF",
    "&:hover": {
      background: "#003366",
    },
  },
  prevButton: {
    background: "#FFF",
    color: "#003366",
    border: "1px solid #A6AEBF",
    "&:hover": {
      background: "#FFF",
    },
  },
}));

const Medicine = (props) => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { medicines, medicineList } = useSelector((state) => {
    return {
      medicineList: state.patient.medicineList,
      medicines: state.patient.createPatient.medicines,
    };
  });
  const [additionalMedicines, setAdditionalMedicines] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState(medicines || []);

  const handleAddEmptyMedicine = () => {
    let clonedAdditionalMedicines = JSON.parse(
      JSON.stringify(additionalMedicines)
    );
    clonedAdditionalMedicines.push({ name: "", dose: 0 });
    setAdditionalMedicines(clonedAdditionalMedicines);
  };

  const handleRemoveMedicine = (index) => {
    let clonedAdditionalMedicines = JSON.parse(
      JSON.stringify(additionalMedicines)
    );
    clonedAdditionalMedicines.splice(index, 1);
    setAdditionalMedicines(clonedAdditionalMedicines);
  };

  const handleChange = (index, flag, value) => {
    let clonedAdditionalMedicines = JSON.parse(
      JSON.stringify(additionalMedicines)
    );
    clonedAdditionalMedicines[index][flag] = value;
    setAdditionalMedicines(clonedAdditionalMedicines);
  };

  const handleChangeMedicine = (checked, medicineID) => {
    const clonedSelectedMedicines = JSON.parse(
      JSON.stringify(selectedMedicines)
    );

    if (checked) {
      clonedSelectedMedicines.push({ medicine_id: medicineID, dose: null });
    } else {
      clonedSelectedMedicines.splice(getIndexMedicine(medicineID), 1);
    }
    setSelectedMedicines(clonedSelectedMedicines);
  };

  const handleChangeDose = (medicineID, dose) => {
    const clonedSelectedMedicines = JSON.parse(
      JSON.stringify(selectedMedicines)
    );
    const index = getIndexMedicine(medicineID);
    if (index !== null) {
      clonedSelectedMedicines[index]["dose"] = dose;
    } else {
      clonedSelectedMedicines.push({ medicine_id: medicineID, dose: dose });
    }
    setSelectedMedicines(clonedSelectedMedicines);
  };

  const getIndexMedicine = (id) => {
    let returnVal = null;
    selectedMedicines.map((item, index) => {
      if (item.medicine_id === id) {
        returnVal = index;
      }
    });
    return returnVal;
  };

  const handleNext = () => {
    // if (selectedMedicines.length === 0) {
    //   addToast("Please select any medicine", {
    //     appearance: "warning",
    //   });
    //   return;
    // }

    dispatch(Actions.setMedicinesData(selectedMedicines));
    dispatch(Actions.setAdditionalMedicinesData(additionalMedicines));
    props.handleNext();
  };

  const getSelectedDose = (medicineID) => {
    let dose = null;
    selectedMedicines.map((medicine) => {
      if (medicineID === medicine.medicine_id) {
        dose = medicine.dose;
      }
    });
    return dose;
  };

  return (
    <Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          I. Leczenie współtowarzyszące wraz z DOBOWĄ dawką:
        </Typography>
        <Box className={classes.formGroupContent}>
          {medicineList.map((medicine, index) => {
            return (medicine.is_visible ?
              <Box key={index}>
                <Ticket
                  {...medicine}
                  dose={getSelectedDose(medicine.id)}
                  handleChangeMedicine={handleChangeMedicine}
                  handleChangeDose={handleChangeDose}
                />
              </Box> : <></>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          II. Inne stosowane leki:
        </Typography>
        <AdditionalMedicineForm
          medicines={additionalMedicines}
          handleRemove={handleRemoveMedicine}
          handleChange={handleChange}
        />
        <Box className={classes.formGroupContent}>
          <Button
            className={classes.addButton}
            onClick={handleAddEmptyMedicine}
          >
            <img src={PlusIcon} alt=":( Not Found" />
            <Typography>Dodaj lek</Typography>
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2} className={classes.formFooter}>
        <Grid item xs={12} lg={6}>
          <Button
            className={classes.prevButton}
            onClick={props.handleBack()}
            fullWidth
          >
            Poprzedni krok
          </Button>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button
            className={classes.nextButton}
            onClick={() => handleNext()}
            fullWidth
          >
            Następny krok
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Medicine;
