import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import API from "apis/API";
import FormInput from "components/form/FormInput";
import Validator, { Required, validate, Custom } from "utils/Validator";
import PrimaryButton from "components/buttons/PrimaryButton";
import {useToasts} from "react-toast-notifications";
import Storage from "utils/Storage";
import { useHistory } from "react-router-dom";
import Routes from "router/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    background: "#FFF",
    minHeight: "100vh",
    "& > .MuiBox-root": {
      width: "100%",
      maxWidth: 1017,
      padding: 0,
      margin: "0 16px",
    },
    [theme.breakpoints.up("lg")]: {
      background: "none",
      "& > .MuiBox-root": {
        padding: "32px 0",
        margin: "0 32px",
      },
    },
  },
  pageTitle: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#003366",
    margin: "24px 0",
    letterSpacing: -2,
    [theme.breakpoints.up("lg")]: {
      margin: 0,
      marginBottom: 16,
      fontSize: 32,
    },
  },
  card: {
    background: "#FFFFFF",
    padding: "0",
    [theme.breakpoints.up("lg")]: {
      borderRadius: 8,
      border: "1px solid #DDE3EE",
      padding: "38px 48px 18px",
    },
  },
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#454D5F",
    marginBottom: 42,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  field: {
    marginBottom: 16,
  },
  button: {
    margin: 0,
    width: "100%",
    marginTop: 24,
  }
}));

const ChangePassword = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {addToast} = useToasts();
  const classes = useStyles();
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    passwordConfirm: '',
  });

  const equalPassword = () => {
    if (data.passwordConfirm === data.newPassword) {
      return "";
    } else {
      return "Pole musi być takie samo jak nowe hasło.";
    }
  };

  const notEqualPassword = () => {
    if (data.oldPassword === data.newPassword) {
      return "Pole musi być inne niż stare hasło";
    } else {
      return "";
    }
  };

  const Validators = {
    oldPassword: new Validator(Required),
    newPassword: new Validator(Required, Custom(notEqualPassword)),
    passwordConfirm: new Validator(Required, Custom(equalPassword)),
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  }

  const handleSave = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;

    API.changePassword(data).then(res => {
      if (res.data.status === 'success') {
        Storage.set("user", JSON.stringify(res.data.user));
        addToast(t('pages.changePassword.changed_password'), { appearance: 'success' });
        setTimeout(() => {
          history.push(Routes.Dashboard.PatientList);
        }, 1000);
      }
      else
        addToast(t("pages.changePassword.short"), { appearance: 'error' });
    })
    .catch((error) => {
      addToast(t("pages.changePassword.wrong_password"), { appearance: "error" });
    });
  }

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.pageTitle}>
          {t("pages.changePassword.title")}
        </Typography>
        <Box className={classes.card}>
          <FormInput
            className={classes.field}
            name="oldPassword"
            title={t('pages.changePassword.oldPassword')}
            value={data?.oldPassword}
            onChange={handleChange}
            error={errors?.oldPassword}
            type="password"
            required
          />
          <FormInput
            className={classes.field}
            name="newPassword"
            title={t('pages.changePassword.newPassword')}
            value={data?.newPassword}
            onChange={handleChange}
            error={errors?.newPassword}
            type="password"
            required
          />
          <FormInput
            className={classes.field}
            name="passwordConfirm"
            title={t('pages.changePassword.passwordConfirm')}
            value={data?.passwordConfirm}
            onChange={handleChange}
            error={errors?.passwordConfirm}
            type="password"
            required
          />

          <PrimaryButton
            className={classes.button}
            onClick={handleSave}
          >
            {t('layout.changePassword')}
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
