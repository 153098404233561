import React, {useState} from "react";
import {Box, Card, makeStyles, Typography} from "@material-ui/core";
import PersonalMedicalData from "components/pagesParts/Create/PersonalMedicalData";
import StepContent from "components/pagesParts/Create/StepContent";
import Criteria from "components/pagesParts/Create/InclusionCriteria";
import Leki from "components/pagesParts/Create/Medicine";
import Qualification from "components/pagesParts/Create/ExclusionCriteria";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        padding: "50px 20px",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 10),
        },
    },
    card: {
        width: "100%",
        maxWidth: 1062,
        borderRadius: 8,
        border: "1px solid #DDE3EE",
        background: "#FFF",
        boxShadow: "none",
        padding: "64px 128px",
        [theme.breakpoints.down("md")]: {
            border: "none",
            borderRadius: 0,
            padding: theme.spacing(2),
        },
    },
    cardTitle: {
        fontSize: 32,
        fontWeight: 700,
        color: "#454D5F",
        letterSpacing: "-1px",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down("md")]: {
            fontSize: 20,
            marginBottom: theme.spacing(3),
        },
    },
    stepperHeader: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        marginBottom: theme.spacing(8),
        "& > .MuiBox-root": {
            zIndex: 1,
            width: "85px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > .MuiBox-root": {
                width: 45,
                height: 45,
                marginBottom: 7,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#DDE3EE",
                border: "1.5px solid #A6AEBF",
                borderRadius: 100,
                fontSize: 16,
                fontWeight: 700,
                color: "#6C7689",
            },
            "& .MuiTypography-root": {
                fontSize: 10,
                fontWeight: 700,
                lineHeight: "14px",
                textTransform: "uppercase",
                color: "#6C7689",
                textAlign: "center",
            },
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(3),
        },
    },
    line: {
        position: "absolute",
        width: "calc(100% - 90px)!important",
        height: 1,
        left: 40,
        top: 22,
        background: "#DDE3EE",
    },
    activeStep: {
        "& .MuiBox-root": {
            background: "#FFF!important",
            borderColor: "#0066CC!important",
            color: "#0066CC!important",
        },
        "& .MuiTypography-root": {
            color: "#0066CC!important",
        },
    },
    completedStep: {
        "& .MuiBox-root": {
            background: "#003366!important",
            borderColor: "#003366!important",
            color: "#FFF!important",
        },
        "& .MuiTypography-root": {
            color: "#003366!important",
        },
    },
}));

const CreatePatient = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        "Dane osobowe i MEDYCZNE",
        "KRYTERIA WŁĄCZENIA",
        "leki",
        "kwalifikacja",
    ];

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    return (
        <Box className={classes.root}>
            <Card className={classes.card}>
                <Typography className={classes.cardTitle}>
                    Rejestracja nowego pacjenta
                </Typography>
                <Box className={classes.stepperHeader}>
                    <Box className={classes.line}></Box>
                    {steps.map((label, index) => {
                        return (
                            <Box
                                key={label}
                                className={`${index === activeStep ? classes.activeStep : ``} ${
                                    index < activeStep ? classes.completedStep : ``
                                }`}
                            >
                                <Box
                                    style={{cursor: "pointer"}}
                                    /*onClick={() => setActiveStep(index)}*/
                                >
                                    {index + 1}
                                </Box>
                                <Typography>{label}</Typography>
                            </Box>
                        );
                    })}
                </Box>
                <Box className={classes.stepperBody}>
                    {activeStep === 0 && (
                        <StepContent title="Dane osobowe i medyczne">
                            <PersonalMedicalData handleNext={() => handleNext()}/>
                        </StepContent>
                    )}
                    {activeStep === 1 && (
                        <StepContent title="Kryteria włączenia">
                            <Criteria
                                handleBack={() => handleBack}
                                handleNext={() => handleNext()}
                            />
                        </StepContent>
                    )}
                    {activeStep === 2 && (
                        <StepContent title="Leki">
                            <Leki
                                handleBack={() => handleBack}
                                handleNext={() => handleNext()}
                            />
                        </StepContent>
                    )}
                    {activeStep === 3 && (
                        <StepContent title="Kwalifikacja">
                            <Qualification
                                handleBack={() => handleBack}
                                handleNext={() => handleNext()}
                            />
                        </StepContent>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default CreatePatient;
