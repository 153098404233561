import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useToasts} from "react-toast-notifications";
import {Box, Card, makeStyles, Typography} from "@material-ui/core";

import API from "apis/API";
import * as Actions from "store/actions/PatientAction";
import Routes from "router/Routes";

import PersonalMedicalData from "components/pagesParts/Update/Personal";
import StepContent from "components/pagesParts/Create/StepContent";
import Criteria from "components/pagesParts/Update/InclusionCriteria";
import Medicine from "components/pagesParts/Update/Medicine";
import ExclusionCriteria from "components/pagesParts/Update/ExclusionCriteria";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        padding: "50px 20px",
    },
    card: {
        width: "100%",
        maxWidth: 1062,
        borderRadius: 8,
        border: "1px solid #DDE3EE",
        background: "#FFF",
        boxShadow: "none",
        padding: "64px 128px",
    },
    cardTitle: {
        fontSize: 32,
        fontWeight: 700,
        color: "#454D5F",
        letterSpacing: "-1px",
        marginBottom: 48,
    },
    stepperHeader: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        marginBottom: 64,
        "& > .MuiBox-root": {
            zIndex: 1,
            width: "85px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > .MuiBox-root": {
                width: 45,
                height: 45,
                marginBottom: 7,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#DDE3EE",
                border: "1.5px solid #A6AEBF",
                borderRadius: 100,
                fontSize: 16,
                fontWeight: 700,
                color: "#6C7689",
            },
            "& .MuiTypography-root": {
                fontSize: 10,
                fontWeight: 700,
                lineHeight: "14px",
                textTransform: "uppercase",
                color: "#6C7689",
                textAlign: "center",
            },
        },
    },
    line: {
        position: "absolute",
        width: "calc(100% - 90px)!important",
        height: 1,
        left: 40,
        top: 22,
        background: "#DDE3EE",
    },
    activeStep: {
        "& .MuiBox-root": {
            background: "#FFF!important",
            borderColor: "#0066CC!important",
            color: "#0066CC!important",
        },
        "& .MuiTypography-root": {
            color: "#0066CC!important",
        },
    },
    completedStep: {
        "& .MuiBox-root": {
            background: "#003366!important",
            borderColor: "#003366!important",
            color: "#FFF!important",
        },
        "& .MuiTypography-root": {
            color: "#003366!important",
        },
    },
}));

const UpdatePatient = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        "DAne osobowe i MEDYCZNE",
        "KRYTERIA WŁĄCZENIA",
        "leki",
        "kwalifikacja",
    ];
    const {
        updatePatient,
        selectedPatientID,
    } = useSelector((state) => {
        return {
            updatePatient: state.patient.updatePatient,
            selectedPatientID: state.card.selectedPatientID,
        };
    });

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    const handleNext = () => {
        setActiveStep(prev => prev + 1);
    };

    const handleStop = () => {
        history.push(Routes.Dashboard.PatientCard);
    };

    const handleSave = (data = {}) => {
        API.patient
            .update(selectedPatientID, {...updatePatient, ...data})
            .then((res) => {
                if (res.data.status === "success") {
                    addToast(t("common.patient_updated"), {
                        appearance: "success",
                    });
                    history.push("/patient/card");
                    dispatch(Actions.formatUpdatePatientData());
                } else {
                    if (res.data.status === "exist_patient") {
                        addToast(t("common.exist_patient"), {
                            appearance: "error",
                        });
                    } else if (res.data.status === "validation_error") {
                        addToast(t("common.validation_error"), {
                            appearance: "error",
                        });
                    }
                }
            })
            .catch((err) => {
                addToast("Server errors", {
                    appearance: "error",
                });
                console.log(err);
            });
    };

    return (
        <Box className={classes.root}>
            <Card className={classes.card}>
                <Typography className={classes.cardTitle}>
                    Edycja danych pacjenta
                </Typography>
                <Box className={classes.stepperHeader}>
                    <Box className={classes.line}></Box>
                    {steps.map((label, index) => {
                        return (
                            <Box
                                key={label}
                                className={`${index === activeStep ? classes.activeStep : ``} ${
                                    index < activeStep ? classes.completedStep : ``
                                }`}
                            >
                                <Box
                                    style={{cursor: "pointer"}}
                                    onClick={() => setActiveStep(index)}
                                >
                                    {index + 1}
                                </Box>
                                <Typography>{label}</Typography>
                            </Box>
                        );
                    })}
                </Box>
                <Box className={classes.stepperBody}>
                    {activeStep === 0 && (
                        <StepContent title="Dane osobowe i medyczne">
                            <PersonalMedicalData
                                handleStop={handleStop}
                                handleNext={handleNext}
                                handleSave={handleSave}
                            />
                        </StepContent>
                    )}
                    {activeStep === 1 && (
                        <StepContent title="Kryteria włączenia">
                            <Criteria
                                handleStop={handleStop}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                handleSave={handleSave}
                            />
                        </StepContent>
                    )}
                    {activeStep === 2 && (
                        <StepContent title="Leki">
                            <Medicine
                                handleStop={handleStop}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                handleSave={handleSave}
                            />
                        </StepContent>
                    )}
                    {activeStep === 3 && (
                        <StepContent title="Kwalifikacja">
                            <ExclusionCriteria
                                handleStop={handleStop}
                                handleBack={handleBack}
                                handleSave={handleSave}
                            />
                        </StepContent>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default UpdatePatient;
