import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    Typography,
    Tooltip,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ReactCalendar from "react-calendar";
import {useHistory} from "react-router";
import ArrowRightImage from "assets/images/icons/arrow-right.svg";
import ArrowLeftImage from "assets/images/icons/arrow-left.svg";
import * as Actions from "store/actions/CardAction";
import moment from "moment";
import Visits from "../../components/patientCardTabs/Visits";
import Constants from "utils/Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2),
            background: "#FFF",
            paddingBottom: theme.spacing(10),
        },
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        color: "#003366",
        letterSpacing: -1,
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            fontSize: 20,
        },
    },
    card: {
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        padding: theme.spacing(4),
        boxShadow: "none",
        position: "relative",
        [theme.breakpoints.down("md")]: {
            border: "none",
            padding: 0,
        },
    },
    cardHeader: {
        width: "calc(100% - 64px)",
        position: "absolute",
        marginBottom: theme.spacing(7),
        display: "flex",
        justifyContent: "flex-end",
        background: "#F7F8FB",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        padding: "26px 32px",
        "& .MuiButton-root": {
            minWidth: 240,
            height: 56,
            background: "#003366",
            borderRadius: 8,
            padding: 28,
            fontSize: 16,
            fontWeight: 700,
            color: "#FFF",
            textTransform: "none",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    calendarContainer: {
        paddingTop: 32,
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    },
    calendar: {
        width: "100%!important",
        padding: 0,
        border: "none",
        background: "none",
        borderRadius: 0,
        [theme.breakpoints.up("lg")]: {
            borderRadius: 8,
        },
        [theme.breakpoints.down("md")]: {
            "& > div": {
                padding: "0px!important",
                justifyContent: "space-between",
                marginBottom: "0px!important",
            },
        },
    },
    tile: {
        padding: 1,
    },
    tileContent: {
        height: 132,
        background: "#FFFFFF",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        fontFamily: "Lato",
        fontSize: 24,
        color: "#003366",
        transition: "0.1s",
        textAlign: "right",
        padding: theme.spacing(1),
        position: "relative",
        "&:hover": {
            opacity: 0.5,
        },
        [theme.breakpoints.down("lg")]: {
            height: 110,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: 700,
            color: "#003366",
        },
        [theme.breakpoints.down("md")]: {
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: 700,
            color: "#003366",
        },
    },
    activeTileContent: {
        background: "#E5F2FF!important",
        border: "1.5px solid #0066CC",
        [theme.breakpoints.down("md")]: {
            background: "#0066CC!important",
            border: "1.5px solid #0066CC",
            color: "#FFF",
        },
    },
    currentDayTileContent: {
        background: "#89c4ff!important",
        border: "1.5px solid #0066CC",
        [theme.breakpoints.down("md")]: {
            background: "#0066CC!important",
            border: "1.5px solid #0066CC",
            color: "#FFF",
        },
    },
    navigationButton: {
        minWidth: "32px!important",
        width: 32,
        height: 32,
        borderRadius: 8,
        background: "#FFF!important",
        border: "1.5px solid #DDE3EE!important",
    },
    schedules: {
        background: "#F7F8FB",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        marginLeft: theme.spacing(3),
        marginTop: 164,
        [theme.breakpoints.down("md")]: {
            background: "#FFF",
            marginTop: 24,
            marginLeft: 0,
            border: "none",
            borderRadius: 0,
            borderTop: "1px solid #DDE3EE",
        },
    },
    scheduleDate: {
        padding: theme.spacing(3, 3, 7, 3),
        color: "#003366",
        fontSize: 24,
        fontWeight: 700,
        borderBottom: "1px solid #DDE3EE",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(3, 0, 2),
            fontSize: 16,
            borderBottom: "none",
        },
    },
    scheduleList: {
        maxHeight: 555,
        minHeight: 555,
        overflowY: "scroll",
        padding: theme.spacing(2, 4),
        [theme.breakpoints.down("md")]: {
            maxHeight: "auto",
            minHeight: "auto",
            padding: 0,
            overflowY: "auto",
        },
    },
    schedule: {
        background: "#FFFFFF",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        overflow: "hidden",
        marginBottom: 16,
        "& .MuiButton-root": {
            width: "100%",
            height: 48,
            fontSize: 14,
            fontWeight: 700,
            color: "#FFF",
            background: "#003366",
            borderRadius: 0,
        },
    },
    scheduleHeader: {
        padding: theme.spacing(1, 2, 2),
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: 16,
            "&:first-child": {
                color: "#6C7689",
                lineHeight: "24px",
                marginBottom: 4,
            },
            "&:last-child": {
                color: "#003366",
                lineHeight: "24px",
            },
        },
    },
    count: {
        position: "absolute",
        bottom: 15,
        left: 15,
        display: "flex",
        alignItems: "center",
        "& .MuiBox-root": {
            background: "#003366",
            width: 30,
            height: 30,
            borderRadius: "50%",
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 9,
        },
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#003366",
        },
        [theme.breakpoints.down("md")]: {
            right: 0,
            bottom: 2,
            left: 'auto',
            "& .MuiBox-root": {
                background: "#003366",
                width: 16,
                height: 16,
                fontSize: 10,
                marginRight: 2
            },
            "& .MuiTypography-root": {
                display: "none"
            }
        },
    },
}));

const Calendar = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const isWeekend = (date) => {
        return date.getDay() === 0 || date.getDay() === 6;
    };
    const [date, onChangeDate] = useState(new Date());
    const today = new Date();
    const todayStartPoint = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
    );

    const {visitsBySelectedDate, visitCount} = useSelector(state => state.card);

    const getTileContent = (date) => {
        const dateStr = moment(date).format("YYYY-MM-DD");
        let count;
        visitCount?.map((visit) => {
            if (visit.date === dateStr) {
                count = visit.count;
            }
        });
        return (
            <Tooltip title={<h3>{"Ilość wizyt: "+(count ?? 0)}</h3>}><Box
                className={`${classes.tileContent} ${
                    date.getTime() === todayStartPoint.getTime()
                        ? classes.currentDayTileContent
                        : ``
                } ${isWeekend(date) ? classes.weekendTileContent : ``}`}
            >
                {date.getDate()}
                {count && (
                    <Box className={classes.count}>
                        <Box>{count}</Box>
                        {/*<Typography>Wizyt</Typography>*/}
                    </Box>
                )}
            </Box></Tooltip>
        );
    };

    useEffect(() => {
        dispatch(
            Actions.getVisitCount({
                year: moment(date).format("YYYY"),
                month: moment(date).format("MM"),
            })
        );
        dispatch(
            Actions.getVisitsBySelectedDate(moment(date).format("YYYY-MM-DD"))
        );
    }, [date]);

    const NextLabel = () => {
        return (
            <Button className={classes.navigationButton}>
                <img src={ArrowRightImage} alt=":( Not Found"/>
            </Button>
        );
    };

    const PrevLabel = () => {
        return (
            <Button className={classes.navigationButton}>
                <img src={ArrowLeftImage} alt=":( Not Found"/>
            </Button>
        );
    };

    const handleChangeView = (date) => {
        dispatch(
            Actions.getVisitCount({
                year: moment(date).format("YYYY"),
                month: moment(date).format("MM"),
            })
        );
    };

    const handleRedirect = (data) => {
        dispatch(Actions.setSelectedPatientID(data.patient_id));
        dispatch(Actions.deleteSelectedTab());
        /*dispatch(
            Actions.setSelectedTab('visits')
        );*/
        history.push("/patient/card");
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>Kalendarz</Typography>
            <Card className={classes.card}>
                <Box className={classes.cardBody}>
                    <Grid container className="schedule">
                        <Grid item xs={12} lg={8} className={classes.calendarContainer}>
                            <ReactCalendar
                                formatDay={() => null}
                                onChange={onChangeDate}
                                onActiveStartDateChange={({
                                                              action,
                                                              activeStartDate,
                                                              value,
                                                              view,
                                                          }) => handleChangeView(activeStartDate)}
                                value={date}
                                className={classes.calendar}
                                locale="pl-PL"
                                tileClassName={classes.tile}
                                tileContent={({date, activeStartDate}) =>
                                    getTileContent(date, activeStartDate)
                                }
                                nextLabel={<NextLabel/>}
                                prevLabel={<PrevLabel/>}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box className={classes.schedules}>
                                <Box className={classes.scheduleDate}>
                                    {Constants.days[moment(date).format("dddd")]},{" "}
                                    {moment(date).format("DD.MM.YYYY")}
                                </Box>
                                <Box className={classes.scheduleList}>
                                    {visitsBySelectedDate?.data?.map((item, index) => {
                                        return (
                                            <Box className={classes.schedule} key={index}>
                                                <Box className={classes.scheduleHeader}>
                                                    <Typography>
                                                        {item.started_at.slice(0, -3)} -{" "}
                                                        {item.ended_at.slice(0, -3)}
                                                    </Typography>
                                                    <Typography>
                                                        {item.patient.firstname} {item.patient.surname}
                                                    </Typography>
                                                </Box>
                                                <Button onClick={() => handleRedirect(item)}>
                                                    Przejdź do karty pacjenta
                                                </Button>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    );
};

export default Calendar;
