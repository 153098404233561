import React, { useEffect, useState } from "react";
import {Button, Box, makeStyles, Typography, Card, CircularProgress} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions/CardAction";
import MessageModal from "components/modals/MessageModal";
import Moment from "react-moment";
import TrashIcon from "../../assets/images/icons/trash.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
      color: "#454D5F",
    },
    "& .MuiButton-root": {
      padding: "0 24px",
      height: 56,
      background: "#003366",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontSize: 16,
        fontWeight: 700,
        textTransform: "none",
        color: "#FFF",
      },
      "& img": {
        filter: "brightness(100) invert(0)",
        marginRight: 20,
      },
    },
  },
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    boxShadow: "none",
    marginBottom: 16,
    background: "#F7F8FB",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
    },
  },
  cardHeader: {
    borderBottom: "1px solid #DDE3EE",
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 24,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "18px 32px 15px",
      "& img": {
        marginRight: 30,
      },
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    marginBottom: 8,
  },
  time: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#6C7689",
  },
  cardBody: {
    padding: "16px 24px 24px",
    [theme.breakpoints.up("lg")]: {
      padding: 32,
    },
  },
  description: {
    fontFamily: "Lato",
    fontSize: 16,
    color: "#003366",
  },
  deleteButton: {
    width: 32,
    minWidth: 32,
    height: 32,
    background: "#D26360",
    borderRadius: 8,
    "&:hover": {
      background: "#D26360",
    },
    "& img": {
      width: 12.5,
      height: 12.5,
      filter: "brightness(0) invert(1)",
    },
  },
  read: {
    border: '2px solid #D26360',
   /* "& p": {
      color: 'black',
      fontWeight: 800,
    },*/
  }
}));

const Messages = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const messages = useSelector((state) => state.card.messageList);
  const [openModal, setOpenModal] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [status, setStatus] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  console.log(messages);
  const userId = useSelector((state) => state.card.selectedPatient.user_id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.getMessagesList({ user_id: userId }));

    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    setEditingData({});
    setOpenModal(false);
  };

  const handleSave = (data) => {
    if (data.id !== undefined && data.id !== "") {
      dispatch(
        Actions.editMessage(data.id, {
          content: data.content,
          receiver_user_id: userId,
        })
      );
    } else {
      dispatch(
        Actions.sendMessage({ content: data.content, receiver_user_id: userId })
      );
    }
  };

  const handleDelete = (data) => {
    dispatch(
        Actions.deleteMessage(data.id)
    );
  };

  return (
    <Box>
      <Typography className={classes.title}>
        {t("pages.patient.messages.title")}
        <Button
          onClick={() => {
            setStatus("send");
            setOpenModal(true);
          }}
        >
          <Typography>Dodaj nowy komunikat</Typography>
        </Button>
      </Typography>
      {messages.length == 0 && <Typography align={"center"}>Brak komunikatów.</Typography>}
      {messages.map((message, index) => {
        let tempAry = message.created_at.split("T");
        let dateAry = tempAry[0].split("-");
        let dateVal = dateAry.reverse().join(".");
        let timeVal = tempAry[1].split(".")[0];
        const readClass = (message.read_at === null || message.read_at === '') ? ' ' + classes.read : '';
        return (
          <Card
            className={classes.card + readClass}
            key={index}
            onClick={() => {
              if (message.read_at === null || message.read_at === '') {
                setStatus("edit");
              } else {
                setStatus("view");
              }
              setOpenModal(true);
              setEditingData(message);
            }}
          >
            <Box className={classes.cardHeader}>
              <Box>
                <Box>
                  <Typography className={classes.name}>{message.sender.doctor ? message.sender.doctor.firstname+" "+message.sender.doctor.surname : 'Administrator'}</Typography>
                  <Typography className={classes.time}>
                    {"Komunikat z dnia " + dateVal + ", wysłane o " + timeVal}
                  </Typography>
                  {message.read_at != null && <Typography className={classes.time}>
                    <strong>Odczytano <Moment format="DD.MM.YYYY HH:mm">{message.read_at}</Moment></strong>
                  </Typography>}
                </Box>

              </Box>
            </Box>
          </Card>
        );
      })}
      <MessageModal
        openModal={openModal}
        onClose={handleCloseModal}
        editingData={editingData}
        onSave={handleSave}
        onDelete={handleDelete}
        status={status}
      />
    </Box>
  );
};

export default Messages;
