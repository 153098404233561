import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    TextField,
    Typography,
    LinearProgress,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Switch, Dialog
} from "@material-ui/core";
import moment from "moment";
import {useTranslation} from "react-i18next";

import BackIcon from "assets/images/icons/back.svg";
import FileImage from "assets/images/pages/patient/file.png";
import * as Actions from "../../../store/actions/CardAction";
import ProbeDetail from "../Probes/Detail";
import SetsDetail from "../Sets/Detail";
import ConnectSetModal from "../../modals/ConnectSetModal";
import API from "../../../apis/API";
import TrashIcon from "../../../assets/images/icons/trash.svg";
import Dropzone from "../../main/Dropzone";
import DeleteButton from "../../buttons/DeleteButton";
import MultiEcgChart from "../../charts/MultiEcgChart";
import CloseIcon from "../../../assets/images/icons/close.svg";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        padding: 0,
        [theme.breakpoints.up("lg")]: {
            padding: 32,
        },
    },
    card: {
        width: "100%",
        marginTop: 10,
        /*maxWidth: 1062,*/
        border: "none",
        borderRadius: 0,
        boxShadow: "none",
        [theme.breakpoints.up("lg")]: {
            borderRadius: 8,
            border: "1px solid #DDE3EE",
        },
    },
    cardHeader: {
        borderBottom: "1px solid #DDE3EE",
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        [theme.breakpoints.up("lg")]: {
            padding: "38px 28px 32px",
        },
    },
    backButton: {
        minWidth: 48,
        width: 48,
        height: 48,
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        marginRight: 14,
        [theme.breakpoints.up("lg")]: {
            border: "none",
        },
    },
    pageTitle: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#454D5F",
        letterSpacing: -2,
        [theme.breakpoints.up("lg")]: {
            fontSize: 32,
        },
    },
    cardSubHeader: {
        borderBottom: "1px solid #DDE3EE",
        padding: "14px 16px",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#003366",
        },
        "& .MuiBox-root": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "20px 28px",
            "& .MuiBox-root": {
                flexDirection: "row",
                alignItems: "center",
            },
        },
    },
    name: {
        fontSize: 20,
        marginBottom: 9,
        letterSpacing: -2,
        [theme.breakpoints.up("lg")]: {
            fontSize: 32,
            marginBottom: 17,
        },
    },
    date: {
        fontSize: 20,
        marginRight: 27,
        [theme.breakpoints.up("lg")]: {
            fontSize: 24,
        },
    },
    time: {
        fontSize: 16,
    },
    cardBody: {
        padding: 16,
        [theme.breakpoints.up("lg")]: {
            padding: "32px 28px 78px",
        },
    },
    label: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 20,
        color: "#454D5F",
        marginBottom: 16,
        [theme.breakpoints.up("lg")]: {
            marginBottom: 24,
        },
    },
    sampling: {
        marginBottom: 74,
        "& > .MuiBox-root": {
            border: "1px solid #DDE3EE",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: 16,
            "& > .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                "& > img": {
                    marginRight: 38,
                    width: 32,
                },
                "& > .MuiBox-root": {
                    "& > .MuiTypography-root": {
                        fontFamily: "Lato",
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#454D5F",
                        marginBottom: 10,
                        "&:first-child": {
                            fontSize: 24,
                            color: "#003366",
                            marginBottom: 8,
                        },
                        "&:last-child": {
                            color: "#003366",
                            marginBottom: 0,
                        },
                    },
                },
            },
            "& > .MuiButton-root": {
                width: "100%",
                border: "2px solid #003366",
                borderRadius: 8,
                height: 56,
                padding: "0 36px",
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 16,
                color: "#003366",
                textTransform: "none",
            },
        },
        [theme.breakpoints.up("lg")]: {
            "& > .MuiBox-root": {
                padding: "30px 24px 40px 32px",
                flexDirection: "row",
                alignItems: "center",
                "& > img": {
                    width: 61,
                },
                "& .MuiButton-root": {
                    width: "auto!important",
                },
            },
        },
    },
    datetimepicker: {
        marginBottom: 34,
        "& .MuiTextField-root": {
            "& .MuiInputBase-input": {
                fontFamily: "Lato",
                fontSize: 16,
                color: "#22272F",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #A6AEBF",
                borderRadius: 8,
            },
        },
    },
    commentHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 16,
        "& .MuiTypography-root": {
            letterSpacing: -1,
            "&:last-child": {
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 20,
                color: "#9F302D",
            },
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: 20,
        },
    },
    commentBody: {
        minHeight: 387,
        padding: "42px 28px",
        background: "#FFFFFF",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        cursor: "pointer"
    },
    commentTitle: {
        fontFamily: "Lato",
        fontWeight: 500,
        fontSize: 21,
        color: "#454D5F",
        marginBottom: 8,
    },
    commentContent: {
        fontFamily: "Lato",
        fontSize: 16,
        color: "#454D5F",
        lineHeight: "26px",
    },
    parameter: {
        marginBottom: 17,

        "& > .MuiBox-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            padding: "0px 0px 34px 0px",

            "& > img": {
                marginRight: 38,
                width: 32,
            },
            "& > button, & > p": {
                width: '100% !important'
            },
            [theme.breakpoints.up("lg")]: {
                flexDirection: "row",
                alignItems: "center",

                "& > button, & > p": {
                    width: 'auto !important'
                },
            },
        },

        "& .MuiButton-root": {
            /* width: "100%",*/
            border: "2px solid #003366",
            borderRadius: 8,
            height: 56,
            padding: "0 36px",
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#003366",
            textTransform: "none",
            width: "auto !important"
        },

        "& .MuiGrid-item": {
            marginBottom: 34,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            "& .MuiTypography-root": {
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 16,
                color: "#6C7689",
                marginRight: 10,
            },
            "& .MuiTextField-root": {
                width: 100,
                "& .MuiOutlinedInput-input": {
                    fontFamily: "Lato",
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#6C7689",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #A6AEBF",
                    borderRadius: 8,
                },
            },
            "&:nth-child(even)": {
                paddingLeft: 0,
            },
            "&:nth-child(odd)": {
                paddingRight: 0,
            },
        },
        [theme.breakpoints.up("lg")]: {
            "& .MuiGrid-item": {
                justifyContent: "space-between",
                "&:nth-child(even)": {
                    paddingLeft: "40px!important",
                },
                "&:nth-child(odd)": {
                    paddingRight: "40px!important",
                },
            },
        },
    },
    ekgHeader: {

        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        padding: "0px 0px 34px 0px",
        "& > p": {
            width: "auto !important"
        },

        "& > .MuiButton-root": {
            width: "auto",
            border: "2px solid #003366",
            borderRadius: 8,
            height: 56,
            padding: "0 36px",
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#003366",
            textTransform: "none",
        },
    },
    ekg: {

        marginBottom: 50,
        "& > .MuiBox-root": {
            display: "flex",

            alignItems: "center",

            "& img": {
                width: 32,
            },

            "& > .MuiBox-root": {
                marginLeft: 14,

                "& .MuiTypography-root": {
                    fontFamily: "Lato",
                    fontSize: 14,
                    color: "#6C7689",
                    "&:first-child": {
                        marginBottom: 3,
                        fontWeight: 700,
                        color: "#003366",
                        textDecoration: "underline",
                    },
                },
            },
        },
        [theme.breakpoints.up("lg")]: {
            "& > .MuiBox-root": {
                "& img": {
                    width: 61,
                },
                "& > .MuiBox-root": {
                    marginLeft: 24,
                },
            },
        },
    },
    table: {
        marginBottom: 37,
    },
    noBorderBox: {
        border: '0 !important',
        padding: '0 !important',

        '& > .MuiBox-root': {
            width: '100%'
        }
    },
    setLoader: {
        marginRight: '10px'
    },
    deleteFile: {
        /*marginTop: 20,*/
        marginRight: 8,
        width: 24,
        height: 24,
        minWidth: 24,
        border: "1px solid #DDE3EE",
        background: "#F7F8FB",
        borderRadius: 6,
        "& img": {
            width: 12.5,
            height: 12.5,
        },
    },
    dropzone: {
        marginBottom: '32px'
    },
    checkbox: {
        marginBottom: 75,
        "& .MuiTypography-root": {
            fontSize: 16,
            fontWeight: 700,
            color: "#22272F",
            letterSpacing: "-0.5px",
        },
    },
    comment: {
        "& > .MuiButton-root": {
            width: "auto",
            float: "right",
            border: "2px solid #003366",
            borderRadius: 8,
            height: 56,
            padding: "0 36px",
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#003366",
            textTransform: "none",
            marginTop: '10px'
        },
    },

    dialog: {
        "& .MuiPaper-root": {
            width: 870,
            maxWidth: 870,
            padding: "20px 32px",
        },
    },
    dialogHeader: {
        marginBottom: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontSize: 20,
            fontWeight: 700,
            color: "#003366",
        },
        "& .MuiButton-root": {
            minWidth: 32,
            width: 32,
            height: 32,
            background: "#FFF",
            border: "1px solid #DDE3EE",
            borderRadius: 8,
        },
    },
    dialogBody: {
        marginBottom: 20,
        "& .ck-content": {
            height: "350px!important",
        },
    },
    dialogFooter: {
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiButton-root": {
            width: 140,
            height: 56,
            borderRadius: 8,
            fontFamily: "Lato",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "none",
            marginLeft: theme.spacing(2),
            border: "1px solid #003366",
            "&:first-child": {
                color: "#003366",
            },
            "&:last-child": {
                color: "#FFF",
                background: "#003366",
            },
        },
    },
}));


const VisitCardDetail = ({onBack}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [data, setData] = useState(null);
    const [isEkgInEditMode, setIsEkgInEditMode] = useState(false);
    const [isCommentInEditMode, setIsCommentInEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSetLoading, setIsSetLoading] = useState(false);
    const [isEkgSaving, setIsEkgSaving] = useState(false);
    const [isConnectSetModalVisible, setIsConnectSetModalVisible] = useState(false);
    const [isProbeDetail, setIsProbeDetail] = useState(false);
    const [isCommentSaving, setIsCommentSaving] = useState(false);
    const [file, setFile] = useState([]);
    const [note, setNote] = useState('');
    const [isEkgChartsVisible, setIsEkgChartsVisible] = useState(false);

    const {selectedVisit} = useSelector(state => state.card);
    const {selectedSetId} = useSelector(state => state.card);

    const dispatch = useDispatch();


    const calculateQTcb = () => {

        if (data && data.results && !isNaN(parseFloat(data?.results[0].rr)) && !isNaN(parseFloat(data?.results[0].qt)) && parseFloat(data?.results[0].rr) != 0) {
            data.results[0].qtcb = parseFloat(parseFloat(data.results[0].qt) / parseFloat(data.results[0].rr)).toFixed(2);
            data.results[0].qtcfra = parseFloat(parseFloat(data.results[0].qt) + 0.154 * (1 - parseFloat(data.results[0].rr))).toFixed(2);
        } else if (data && data.results) {
            data.results[0].qtcb = data.results[0].qtcfra = '';
        }

    }

    useEffect(() => {
        if (data && (!data.results || data?.results.length == 0)) {
            data.results = [];
        }

        //let dataChanged = false;
        /*if ((!data?.results[0].qrs || !data?.results[0].qt) && data?.ecg_data)
        {
          data.results[0] = Object.assign(data.results[0], {
            qrs: data.ecg_data?.VitalSigns?.TwelveLeadReport?.Observations?.Observation[2],
            qt: data.ecg_data?.VitalSigns?.TwelveLeadReport?.Observations?.Observation[3],
          });
        }*/

        calculateQTcb();

        data && setData(data);
    }, [data]);

    const handleShowProbe = (probe) => {
        dispatch(Actions.selectProbe(probe)).then(() => {
            window.scrollTo(0, 0);
            setIsProbeDetail(true);
        });
    };

    const handleDisconnectSet = () => {
        setIsSetLoading(true);

        API.card.visit.disconnectSet(selectedVisit.id).then(res => {
            API.card.visit.show(selectedVisit.id).then(res => {
                setData(res.data.data);
                dispatch(Actions.selectSet(false));
                setIsSetLoading(false);
            });
        });
    };

    const handleChangeEkg = (name, value) => {
        data.results[0][name] = value;
        calculateQTcb();

        setData({...data});
    };

    const handleConnectSet = () => {
        setIsConnectSetModalVisible(true);
    };

    const handleCloseProbe = () => {
        setIsProbeDetail(false);
    };

    const handleSaveComment = () => {
        if (data.notes.length > 0) {
            API.card.note.update(data.notes[0].id, {
                content: note
            })
                .then((res) => {
                    setIsCommentInEditMode(false);
                })
                .catch((err) => {
                    setIsCommentInEditMode(false);
                });
        } else {
            API.card.note.save({
                content: note,
                ref_type: "visit",
                ref_id: selectedVisit.id,
            })
                .then((res) => {
                    setIsCommentInEditMode(false);
                })
                .catch((err) => {
                    setIsCommentInEditMode(false);
                });
        }
    };

    const handleOnConnect = (id) => {
        dispatch(Actions.selectSet(id));
    };

    const handleSaveEkgParameters = () => {
        setIsEkgSaving(true);

        API.card.visit.updateResults(selectedVisit.id, data.results[0]).then(res => {
            handleUploadFile();
        });
    };

    const handleEditComment = () => {
        setIsCommentInEditMode(true);
    }

    const handleDeleteFile = (fileID) => {
        dispatch(Actions.deleteFile(fileID));

        data.files = data.files.filter(item => item.id != fileID);
        setIsEkgChartsVisible(false);

        setData({...data});
    };

    const handleSelectFile = (selectedFile) => {
        setFile(selectedFile);
    };

    const handleDownloadFile = (fileID, filename) => {
        const blobInterceptor = API.interceptors.request.use(
            (config) => {
                config.responseType = "blob";
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        API.card.file.download(fileID).then((res) => {
            API.interceptors.request.eject(blobInterceptor);
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    };

    const handleCloseComment = () => {
        setIsCommentInEditMode(false);
    };

    const handleUploadFile = () => {
        if (file.length == 0) {
            setIsEkgInEditMode(false);
            setIsEkgSaving(false);
            return;
        }

        let bodyFormData = new FormData();
        bodyFormData.append("file", file[0]);
        bodyFormData.append("comment", "");
        bodyFormData.append("ref_type", "visit");
        bodyFormData.append("ref_id", selectedVisit.id);

        setIsEkgSaving(true);

        const formDataInterceptors = API.interceptors.request.use(
            (config) => {
                config.headers["Content-Type"] = "application/x-www-form-urlencoded";
                config.data = bodyFormData;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        API.post("/files").then((res) => {
            API.interceptors.request.eject(formDataInterceptors);

            setIsEkgChartsVisible(false);
            setFile([]);

            API.card.visit.show(selectedVisit.id).then(res => {
                setIsEkgInEditMode(false);
                setIsEkgSaving(false);

                setData(res.data.data);
            });

        });
    };

    const handleChangeStatus = (e, value) => {
        setData({...data, status: data.status == 'past' ? 'upcoming' : 'past'});


        API.card.visit.update(data.id, {status: selectedVisit.status == 'past' ? 'upcoming' : 'past'}).then((res) => {
            dispatch(Actions.updateVisits(res.data.visit));
            onBack();
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        API.card.visit.show(selectedVisit.id).then(res => {
            if (!res.data.data.results.length) {
                res.data.data.results = [{}];
            }

            setData(res.data.data);
            setNote(res.data.data.notes.length > 0 ? res.data.data.notes[0].content : '');

            dispatch(Actions.selectSet(res.data.data.sets[0] ? res.data.data.sets[0].id : false));
            setIsLoading(false);
        });
    }, []);

    return (
        isProbeDetail ? <ProbeDetail onBack={handleCloseProbe}></ProbeDetail> :
            <Box>
                <Card className={classes.card}>
                    <Box className={classes.cardHeader}>
                        <Button className={classes.backButton} onClick={onBack}>
                            <img src={BackIcon} alt=":( Not Found"/>
                        </Button>
                        <Typography className={classes.pageTitle}>Karta wizyty</Typography>
                    </Box>

                    {isLoading && <LinearProgress/>}
                    {!isLoading && <>
                        <Box className={classes.cardSubHeader}>

                            <Box>
                                <Typography className={classes.date}>
                                    Wizyta: {data?.date}
                                </Typography>
                                <Typography className={classes.time}>{data?.started_at} - {data?.ended_at}</Typography>

                            </Box>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch onChange={handleChangeStatus} checked={data.status === 'past'}
                                                     color="primary"/>} label="Wizyta się odbyła"/>
                            </FormGroup>
                        </Box>
                        <Box className={classes.cardBody}>
                            <Box className={classes.parameter}>
                                <Box>
                                    <Typography className={classes.label}>Parametry EKG</Typography>
                                    {!isEkgInEditMode ? <Button onClick={() => {
                                        setIsEkgInEditMode(true)
                                    }}>
                                        Edytuj parametry EKG
                                    </Button> : <Button onClick={() => {
                                        handleSaveEkgParameters(true)
                                    }}>
                                        {isEkgSaving &&
                                            <CircularProgress className={classes.setLoader} size={'1em'}/>} Zapisz
                                        parametry EKG
                                    </Button>}
                                </Box>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography>QT</Typography>
                                        <TextField onChange={(e) => handleChangeEkg("qt", e.target.value)}
                                                   inputProps={{disabled: !isEkgInEditMode,}} variant="outlined"
                                                   value={data.results[0]?.qt}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>RR</Typography>
                                        <TextField onChange={(e) => handleChangeEkg("rr", e.target.value)}
                                                   inputProps={{disabled: !isEkgInEditMode,}} variant="outlined"
                                                   value={data.results[0]?.rr}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>PQ</Typography>
                                        <TextField onChange={(e) => handleChangeEkg("pq", e.target.value)}
                                                   inputProps={{disabled: !isEkgInEditMode,}} variant="outlined"
                                                   value={data.results[0]?.pq}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>QRS</Typography>
                                        <TextField onChange={(e) => handleChangeEkg("qrs", e.target.value)}
                                                   inputProps={{disabled: !isEkgInEditMode,}} variant="outlined"
                                                   value={data.results[0]?.qrs}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>QTcB</Typography>
                                        <TextField onChange={(e) => handleChangeEkg("qtcb", e.target.value)}
                                                   inputProps={{disabled: true,}} variant="outlined"
                                                   value={data.results[0]?.qtcb}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>QTcFra</Typography>
                                        <TextField onChange={(e) => handleChangeEkg("qtcfra", e.target.value)}
                                                   inputProps={{disabled: true,}} variant="outlined"
                                                   value={data.results[0]?.qtcfra}/>
                                    </Grid>
                                </Grid>
                            </Box>
                            {(data.files && data.files.length > 0) && <>
                                <Box className={classes.ekgHeader}>
                                    {((data.files && data.files.length > 0) || isEkgInEditMode) && <>
                                        <Typography className={classes.label}>EKG</Typography>

                                        {data.ecg_data && <>
                                            {!isEkgChartsVisible ? <Button onClick={() => {
                                                setIsEkgChartsVisible(true)
                                            }}>
                                                Pokaż wykresy EKG
                                            </Button> : <Button onClick={() => {
                                                setIsEkgChartsVisible(false)
                                            }}>
                                                Ukryj wykresy EKG
                                            </Button>}</>}
                                    </>
                                    }
                                </Box></>}
                            <Box className={classes.ekg}>

                                {data.ecg_data && !isEkgInEditMode && isEkgChartsVisible &&
                                    <MultiEcgChart ecgData={data.ecg_data}/>
                                }

                                {data.files?.map((file, index) => {
                                    return (
                                        <Box>
                                            <DeleteButton
                                                skipNativeClass={true}
                                                hint={'Czy na pewno chcesz usunąć ten plik?'}
                                                className={classes.deleteFile}
                                                onClickHandler={() => handleDeleteFile(file.id)}
                                            >
                                                <img src={TrashIcon} alt=":( Not Found"/>
                                            </DeleteButton>
                                            <img src={FileImage} alt=":( Not Found"/>
                                            <Box>
                                                <Typography style={{cursor: 'pointer'}} onClick={() =>
                                                    handleDownloadFile(file.id, file.original_name)
                                                }>{file.original_name}</Typography>
                                                <Typography>Dodano: {moment(file.created_at).format('DD.MM.YYYY hh:mm')}</Typography>
                                            </Box>
                                        </Box>)
                                })}
                            </Box>

                            {isEkgInEditMode && <Box className={classes.dropzone}>
                                <Dropzone
                                    accept=".xls, .xlsx, .pdf, .doc, .docx, .txt, .rtf, .xml"
                                    multiple={false}
                                    files={file}
                                    onChange={(files) => handleSelectFile(files)}
                                />
                            </Box>}
                            <Box className={classes.sampling}>
                                {isConnectSetModalVisible &&
                                    <ConnectSetModal open={isConnectSetModalVisible} onConnected={handleOnConnect}
                                                     onClose={() => {
                                                         setIsConnectSetModalVisible(false)
                                                     }}/>}

                                <Box>
                                    <Typography
                                        className={classes.label}>{selectedSetId ? 'Przypisany zestaw próbek:' : 'Brak przypisanego zestawu próbek'}</Typography>

                                    {selectedSetId > 0 && <Button onClick={handleDisconnectSet}>
                                        {isSetLoading &&
                                            <CircularProgress className={classes.setLoader} size={'1em'}/>} Odłącz
                                        zestaw
                                    </Button>}
                                    {!selectedSetId && <Button onClick={handleConnectSet}>
                                        {isSetLoading &&
                                            <CircularProgress className={classes.setLoader} size={'1em'}/>} Podłącz
                                        zestaw
                                    </Button>}
                                </Box>
                                {selectedSetId > 0 &&
                                    <Box className={classes.noBorderBox}>
                                        <SetsDetail onShowProbe={handleShowProbe} showSummary={false}></SetsDetail>
                                    </Box>}
                            </Box>
                            <Box className={classes.comment}>
                                <Box className={classes.commentHeader}>
                                    <Typography className={classes.label} style={{marginBottom: 0}}>
                                        Komentarz:
                                    </Typography>
                                </Box>
                                <Box className={classes.commentBody} onClick={handleEditComment}
                                     dangerouslySetInnerHTML={{__html: note}}>
                                </Box>

                            </Box>

                            <Dialog open={isCommentInEditMode} onClose={handleCloseComment} className={classes.dialog}>
                                <Box className={classes.dialogHeader}>
                                    <Typography>{t("pages.patient.doctorComments.addComment")}</Typography>
                                    <Button onClick={handleCloseComment}>
                                        <img src={CloseIcon} alt=":( Not Found"/>
                                    </Button>
                                </Box>
                                <Box className={classes.dialogBody}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={note}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${process.env.REACT_APP_API_ENDPOINT_URI}patients/notes/upload`,
                                            },
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setNote(data);
                                        }}
                                    />
                                </Box>
                                <Box className={classes.dialogFooter}>
                                    <Button onClick={handleCloseComment}>{t("common.cancel")}</Button>
                                    <Button onClick={handleSaveComment}>
                                        {isCommentSaving ? (
                                            <CircularProgress size={30} style={{color: "#FFF"}}/>
                                        ) : (
                                            <>{t("common.add")}</>
                                        )}
                                    </Button>
                                </Box>
                            </Dialog>

                        </Box></>}
                </Card>
            </Box>
    );
};

export default VisitCardDetail;
