import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    makeStyles,
    Typography,
    TableRow,
    TableCell, Table, TableHead, TableBody, TableContainer, LinearProgress,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import CloseIcon from "assets/images/icons/close.svg";
import PlusIcon from "assets/images/icons/plus.svg";
import CheckIcon from "assets/images/icons/check-circle-default.svg";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import API from "../../apis/API";

const useStyles = makeStyles((theme) => ({
    table: {
        border: "1px solid #DDE3EE",
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(4),
        "& .MuiTableHead-root": {
            background: "#E7ECF5",
        },
        "& .MuiTableCell-body": {
            fontFamily: "Lato",
            fontSize: 20,
            lineHeight: 1.4,
            color: "#003366"
        }
    },
    dialog: {
        "& .MuiPaper-root": {
            width: 800,
            maxWidth: 800
        },
    },
    dialogHeader: {
        padding: "20px 32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 20,
            color: "#003366",
        },
    },
    dialogBody: {
        padding: "20px 32px 0",
    },
    closeButton: {
        width: 32,
        height: 32,
        minWidth: 32,
        border: "1px solid #DDE3EE",
        borderRadius: 8,
    },
    button: {
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2, 3),
        fontFamily: "Lato",
        fontWeight: "bold",
        fontSize: theme.spacing(2),
        lineHeight: 1.5,
    },
    cancelButton: {
        border: "1px solid #A6AEBF",
        color: "#003366",
        marginRight: theme.spacing(2),
    },
    createEmptyButton: {
        background: "#003366",
        color: "#FFFFFF",
        "&:hover": {
            background: "#003366",
        },
        "& img": {
            filter: "brightness(0) invert(1)",
        },
    },
    iconButton: {
        minWidth: 41,
        width: 41,
        height: 41,
        background: "#F7F8FB",
        border: "1.70833px solid #DDE3EE",
        borderRadius: 8,
        marginLeft: 16,
        paddingLeft: 15,
        display: "none",
        [theme.breakpoints.up("xs")]: {
            display: "flex",
        },
    },
}));

const ConnectSetModal = ({ defaultProbes, open, onClose, onConnected, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [sets, setSets] = useState([]);
    const {selectedPatientID} = useSelector(state => state.card);
    const {selectedVisit} = useSelector(state => state.card);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        API.card.sets.getList(selectedPatientID).then(res => {
            setIsLoading(false);
            setSets(res.data.data);
        });
    }, []);


    const renderRow = (set, i) => (
        <TableRow key={i}>
            <TableCell>{t('pages.patient.sets.modal.setNr')} {set.nr}</TableCell>
            <TableCell>{moment(set.created_at).format('DD.MM.YYYY, HH:mm')}</TableCell>
            <TableCell><Button onClick={() => handleSelectSet(set.id)} title={t('pages.patient.sets.modal.selectSet')} className={classes.iconButton}><img src={CheckIcon} alt="print label" style={{ marginRight: 10 }} /></Button></TableCell>
        </TableRow>
    );

    const handleSelectSet = (id) => {
        API.card.visit.connectSet(selectedVisit.id, id).then(res => {
            onConnected && onConnected(id);
            onClose();
        });
    };

    const handleConnectEmpty = () => {
        API.card.visit.connectEmptySet(selectedVisit.id).then(res => {
            onConnected && onConnected(res.data.set.id);
            onClose();
        });
    };

    return (
        <Dialog onClose={onClose} className={classes.dialog} open={open}>
            <Box className={classes.dialogHeader}>
                <Typography>{t('pages.patient.sets.modal.selectSet')}</Typography>
                <Button onClick={onClose} className={classes.closeButton}>
                    <img src={CloseIcon} alt="Close" />
                </Button>
            </Box>
            <Box className={classes.dialogBody}>
                <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('pages.patient.sets.modal.nr')}</TableCell>
                                <TableCell>{t('pages.patient.sets.modal.createdAt')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sets.map(renderRow)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isLoading && <LinearProgress/>}
            </Box>
            <Box display="flex" justifyContent="flex-end" p={4}>
                <Button onClick={onClose} className={clsx(classes.button, classes.cancelButton)}>
                    {t('common.cancel')}
                </Button>
                <Button onClick={handleConnectEmpty} className={clsx(classes.button, classes.createEmptyButton)} >
                    <img src={PlusIcon} alt="print label" style={{ marginRight: 10 }} />
                    {t('pages.patient.sets.modal.createEmpty')}
                </Button>
            </Box>
        </Dialog>
    );
};

export default ConnectSetModal;
