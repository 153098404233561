import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CloseIcon from "assets/images/icons/close.svg";
import API from "apis/API";
import * as Actions from "store/actions/CardAction";
import Note from "./DoctorComment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
      color: "#454D5F",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 870,
      maxWidth: 870,
      padding: "20px 32px",
    },
  },
  dialogHeader: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontSize: 20,
      fontWeight: 700,
      color: "#003366",
    },
    "& .MuiButton-root": {
      minWidth: 32,
      width: 32,
      height: 32,
      background: "#FFF",
      border: "1px solid #DDE3EE",
      borderRadius: 8,
    },
  },
  dialogBody: {
    marginBottom: 20,
    "& .ck-content": {
      height: "350px!important",
    },
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      width: 140,
      height: 56,
      borderRadius: 8,
      fontFamily: "Lato",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      marginLeft: theme.spacing(2),
      border: "1px solid #003366",
      "&:first-child": {
        color: "#003366",
      },
      "&:last-child": {
        color: "#FFF",
        background: "#003366",
      },
    },
  },
  addNote: {
    alignSelf: "flex-end",
    width: 235,
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    textTransform: "none",
    "&:hover": {
      background: "#003366",
    },
  },
}));

const DoctorComments = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [note, setNote] = useState("");
  const patientID = useSelector((state) => state.card.selectedPatientID);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setIsSaving(true);
    API.card.note
      .save({
        content: note,
        ref_type: "patient",
        ref_id: patientID,
      })
      .then((res) => {
        setIsSaving(false);
        setOpen(false);
        setNote("");
        dispatch(Actions.updateNote(res.data.note));
      })
      .catch((err) => {
        setOpen(false);
        setNote("");
        setIsSaving(false);
        console.log(err);
      });
  };

  const { notes } = useSelector((state) => state.card.selectedPatient);

  return (
    <>
      <Box className={classes.root}>
        <Typography className={classes.title}>
          {t("pages.patient.doctorComments.title")}
        </Typography>
        {notes?.map((comment, index) => {
          return <Note {...comment} key={index} />;
        })}
        <Button className={classes.addNote} onClick={() => setOpen(true)}>
          Dodaj nowy komentarz
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <Box className={classes.dialogHeader}>
          <Typography>{t("pages.patient.doctorComments.addComment")}</Typography>
          <Button onClick={handleClose}>
            <img src={CloseIcon} alt=":( Not Found" />
          </Button>
        </Box>
        <Box className={classes.dialogBody}>
          <CKEditor
            editor={ClassicEditor}
            data={note}
            config={{
              ckfinder: {
                uploadUrl: `${process.env.REACT_APP_API_ENDPOINT_URI}patients/notes/upload`,
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setNote(data);
            }}
          />
        </Box>
        <Box className={classes.dialogFooter}>
          <Button onClick={handleClose}>{t("common.cancel")}</Button>
          <Button onClick={handleSave}>
            {isSaving ? (
              <CircularProgress size={30} style={{ color: "#FFF" }} />
            ) : (
              <>{t("common.add")}</>
            )}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default DoctorComments;
