import React from "react";
import ProbesList from 'components/patientParts/Probes';

const Probes = () => {
  return (
    <ProbesList />
  );
};

export default Probes;
