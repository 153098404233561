import React from "react";
import {useSelector} from "react-redux";
import {Box, makeStyles, Modal, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import formatAddress from "utils/formatAddress";
import CredentialsDocument from "../documents/CredentialsDocument";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 0,
        [theme.breakpoints.up("lg")]: {
            paddingTop: theme.spacing(2),
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#003366",
        paddingLeft: 0,
        margin: theme.spacing(3, 0, 2),
        [theme.breakpoints.up("lg")]: {
            margin: theme.spacing(4, 0, 3),
            paddingLeft: theme.spacing(3),
            fontSize: 20,
            color: "#454D5F",
        },
    },
    table: {
        border: "1px solid #DDE3EE",
        borderRadius: "8px",
        overflow: "hidden",
        "& > .MuiBox-root": {
            minHeight: 48,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: "14px 24px",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                alignItems: "center",
                padding: "0 24px",
            },
            "&:nth-of-type(odd)": {
                backgroundColor: "#F7F8FB",
            },
            "& .MuiTypography-root": {
                fontFamily: "Lato",
                "&:first-child": {
                    fontSize: 12,
                    color: "#454D5F",
                    marginBottom: 8,
                    textTransform: "uppercase",
                    [theme.breakpoints.up("sm")]: {
                        fontSize: 16,
                        marginBottom: 0,
                        textTransform: "none",
                    },
                },
                "&:last-child": {
                    fontSize: 16,
                    color: "#003366",
                    [theme.breakpoints.up("sm")]: {
                        fontSize: 20,
                    },
                },
            },
        },
    },
}));

const Personal = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const selectedPatient = useSelector((state) => state.card.selectedPatient);

    const medicalData = [
        {
            name: t("pages.patient.personal.address"),
            value: formatAddress(selectedPatient)
        },
        {
            name: t("pages.patient.personal.phone"),
            value: selectedPatient?.phone_number,
        },
        {
            name: t("pages.patient.personal.email"),
            value: selectedPatient?.email,
        },
    ];

    const doctorUnitData = [
        {
            name: t("pages.patient.doctor_unit.doctor"),
            value: selectedPatient?.doctor?.firstname + " " + selectedPatient?.doctor?.surname
        },
        {
            name: t("pages.patient.doctor_unit.unit"),
            value: selectedPatient?.unit?.name
        },
    ];

    const contactData = [
        {
            name: t("pages.patient.personal.libra"),
            value: `${selectedPatient?.weight} kg`,
        },
        {
            name: t("pages.patient.personal.increase"),
            value: `${selectedPatient?.height} cm`,
        },
        {
            name: t("pages.patient.personal.longTxt"),
            value: selectedPatient?.id,
        },
        {
            name: "Grupa",
            value:
                selectedPatient?.patient_inclusion_criteria_sub_group?.group ? selectedPatient?.patient_inclusion_criteria_sub_group?.group?.nr : '-'
        },
        {
            name: "Podgrupa",
            value:
                selectedPatient?.patient_inclusion_criteria_sub_group?.subgroup ? (selectedPatient?.patient_inclusion_criteria_sub_group?.subgroup?.name +
                    " - " +
                    selectedPatient?.patient_inclusion_criteria_sub_group?.subgroup
                        ?.description) : '-',
        },
    ];

    const programData = [
        {
            name: "Zakwalifikowany do programu",
            value: selectedPatient?.is_classified === 1 ? "Tak" : "Nie",
        },
        {
            name: "Zakończenie programu",
            value: selectedPatient?.is_finished === 1 ? "Tak" : "Nie",
        },
    ];

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>
                {t("pages.patient.personal.contactDetails")}
            </Typography>
            <Box className={classes.table}>
                {medicalData.map((data, index) => {
                    return (
                        <Box key={index}>
                            <Typography>{data.name}</Typography>
                            <Typography>{data.value}</Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box className={classes.table} style={{marginTop: '10px'}}>
                {doctorUnitData.map((data, index) => {
                    return (
                        <Box key={index}>
                            <Typography>{data.name}</Typography>
                            <Typography>{data.value}</Typography>
                        </Box>
                    );
                })}
            </Box>
            <Typography className={classes.title}>
                {t("pages.patient.personal.medicalData")}
            </Typography>
            <Box className={classes.table}>
                {contactData.map((data, index) => {
                    return (
                        <Box key={index}>
                            <Typography>{data.name}</Typography>
                            <Typography>{data.value}</Typography>
                        </Box>
                    );
                })}
            </Box>
            <Typography className={classes.title}>
                {t("pages.patient.personal.programData")}
            </Typography>
            <Box className={classes.table}>
                {programData.map((data, index) => {
                    return (
                        <Box key={index}>
                            <Typography>{data.name}</Typography>
                            <Typography>{data.value}</Typography>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Personal;
