import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TextInput from "components/form/TextInput";
import Routes from "router/Routes";
import FooterImage from "assets/images/pages/login/footer.png";
import API from "apis/API";
import Storage from "utils/Storage";
import * as Actions from "store/actions/AuthAction";
import Splash from 'assets/images/cardio_splash.jpg';


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
  },
  leftPanel: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    background: "#F7F8FB",
  },
  footerImage: {
    width: "100%",
    padding: "24px 16px 20px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "30px 60px",
    },
    "& img": {
      width: "auto",
      maxWidth: "100%",
      mixBlendMode: "darken",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4, 2),
    height: "100vh",
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 16),
      justifyContent: "flex-start",
    },
  },
  title: {
    fontFamily: "Lato",
    fontSize: 24,
    fontWeight: 700,
    color: "#454D5F",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
    },
  },
  login: {
    width: "100%",
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    marginTop: theme.spacing(6),
    "&:hover": {
      background: "#003366",
    },
  },
  rightPanel: {
    display: "none",
    backgroundImage: `url(${Splash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    setIsLoading(true);
    API.auth
      .login({
        login: email,
        password,
      })
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          dispatch(Actions.setAuthenticatedUserData(res.data.user));

          Storage.set("token", res.data.access_token);
          Storage.set("user", JSON.stringify(res.data.user));

          history.push(Routes.Dashboard.PatientList);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        addToast(t("pages.login.errorMsg"), { appearance: "warning" });
      });
  };

  const handleSubmit = (e) => {
    e.which === 13 && handleLogin();
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} lg={5}>
        <Box className={classes.leftPanel}>
          <Box className={classes.form} onKeyPress={handleSubmit}>
            <Box>
              <Typography className={classes.title}>
                {t("pages.login.title")}
              </Typography>
              <TextInput
                label={t("pages.login.username")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                type="password"
                label={t("pages.login.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Button onClick={() => handleLogin()} className={classes.login}>
              {isLoading ? (
                <CircularProgress size={30} style={{ color: "#FFF" }} />
              ) : (
                <>{t("pages.login.login")}</>
              )}
            </Button>
          </Box>
          <Box className={classes.footerImage}>
            <img src={FooterImage} alt=":( Not Found" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={7} className={classes.rightPanel}></Grid>
    </Grid>
  );
};

export default Login;
