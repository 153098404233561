import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import Routes from "router/Routes";
import Storage from "utils/Storage";
import * as Actions from "store/actions/CardAction";
import Personal from "components/patientCardTabs/Personal";
import Medications from "components/patientCardTabs/Medications";
import Visits from "components/patientCardTabs/Visits";
import Attachments from "components/patientCardTabs/Attachments";
import DoctorComments from "components/patientCardTabs/DoctorComments";
import Messages from "components/patientCardTabs/Messages";
import CloseIcon from "assets/images/icons/close_md.svg";
import MenuIcon from "assets/images/icons/menu.svg";
import LogoutIcon from "assets/images/icons/logout.svg";
import KeyIcon from "assets/images/icons/key.svg";
import AvatarIcon from "assets/images/icons/avatar.svg";
import BookmarkActiveImage from "assets/images/icons/active_bookmark.png";
import BookmarkNormalImage from "assets/images/icons/normal_bookmark.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "100%",
    background: "#FFF",
    position: "fixed",
    zIndex: 10,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  profile: {
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #DDE3EE",
    "& .MuiButton-root": {
      width: 48,
      height: 48,
      minWidth: 48,
      border: "1px solid #DDE3EE",
      borderRadius: 8,
      marginRight: theme.spacing(2),
    },
    "& .MuiTypography-root": {
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
    },
  },
  header: {
    background: "#FFF",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #DDE3EE",
  },
  hamburgerButton: {
    minWidth: 48,
    width: 48,
    height: 48,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    background: "#FFF",
    marginRight: 16,
  },
  title: {
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    color: "#454D5F",
  },
  breadcrumb: {
    minHeight: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "14px 16px",
    borderBottom: "1px solid #DDE3EE",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#454D5F",
    },
  },
  isOpened: {
    transform: "translateX(0)!important",
  },
  body: {
    width: "100%",
    position: "absolute",
    background: "#FFF",
    minHeight: "calc(100vh - 140px)",
    transition: "0.3s",
    transform: "translateX(-100%)",
  },
  route: {
    minHeight: 55,
    padding: "0 24px",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    "& .MuiTypography-root": {
      marginLeft: 16,
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#454D5F",
    },
  },
  activeRoute: {
    background: "#E5F2FF",
    "& .MuiTypography-root": {
      color: "#003366",
    },
  },
}));

const MobileHeader = ({ title }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const routes = [
    {
      id: "personal",
      name: t("pages.patient.layout.personalMedicalData"),
      component: <Personal />,
    },
    {
      id: "medications",
      name: t("pages.patient.layout.medicationTaken"),
      component: <Medications />,
    },
    {
      id: "visits",
      name: t("pages.patient.layout.visits"),
      component: <Visits />,
    },
    {
      id: "comments",
      name: t("pages.patient.layout.doctorComments"),
      component: <DoctorComments />,
    },
    {
      id: "attachments",
      name: t("pages.patient.layout.attachments"),
      component: <Attachments />,
    },
    {
      id: "messages",
      name: t("pages.patient.layout.messages"),
      component: <Messages />,
    },
  ];

  const userInfo = JSON.parse(Storage.get("user"));

  const handleToggle = () => {
    setIsOpen(!isOpen);
  //  document.body.style.overflow = isOpen ? "auto" : "hidden";
    //document.body.style.overflow = isOpen ? "auto" : "hidden";
  };

  const { selectedTab, selectedPatient } = useSelector((state) => state.card);

  const handleRedirect = (data) => {
    setIsOpen(false);
    dispatch(Actions.setSelectedTab(data.id));
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.profile}>
        <Button>
          <img src={AvatarIcon} alt=":( Not Found" />
        </Button>
        <Typography>
          {`${selectedPatient?.firstname} ${selectedPatient?.surname}`}
        </Typography>
      </Box>
      <Box className={classes.header}>
        <Button className={classes.hamburgerButton} onClick={handleToggle}>
          {isOpen ? (
            <img src={CloseIcon} alt=":( Not Found" />
          ) : (
            <img src={MenuIcon} alt=":( Not Found" />
          )}
        </Button>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box className={`${classes.body} ${isOpen ? classes.isOpened : ``}`}>
        <Box className={classes.breadcrumb}>
          <Typography>
            {`${userInfo?.doctor?.firstname} ${userInfo?.doctor?.surname}`}
          </Typography>
          <Button>
            <Link to={Routes.Dashboard.ChangePassword}>
              <img src={KeyIcon} alt=":( Not Found" />
            </Link>
            <Link to={Routes.Auth.Login}>
              <img src={LogoutIcon} alt=":( Not Found" />
            </Link>
          </Button>
        </Box>
        <Box className={classes.routes}>
          {routes.map((route, index) => {
            return (
              <Button
                key={index}
                className={`${classes.route} ${selectedTab === route ? classes.activeRoute : ""
                  }`}
                onClick={() => handleRedirect(route)}
              >
                <Box style={{ display: "flex" }}>
                  {location.pathname === route.link ? (
                    <img src={BookmarkActiveImage} alt=":( Not Found" />
                  ) : (
                    <img src={BookmarkNormalImage} alt=":( Not Found" />
                  )}
                </Box>
                <Typography>{route.name}</Typography>
              </Button>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileHeader;
