import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    makeStyles,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
} from "@material-ui/core";

import BackIcon from "assets/images/icons/back.svg";
import InfoIcon from "assets/images/icons/info.svg";
import ChemicalFormulaImage from "assets/images/pages/patient/chemical-formula.png";

import Title from "components/text/Title";
import Constants from 'utils/Constants';
import API from "apis/API";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "22px 32px",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2, 0, 10),
            background: "#FFF",
        },
    },
    goBack: {
        fontSize: 16
    },
    backButton: {
        minWidth: 40,
        width: 40,
        height: 40,
        marginRight: 16,
        padding: 10,
        border: "1px solid #DDE3EE",
        borderRadius: 12
    },
    container: {
        border: "1px solid #DDE3EE",
        background: "#fff",
        borderRadius: 8,
        "& > .MuiBox-root": {
            padding: 40
        }
    },
    header: {
        borderBottom: "1px solid #DDE3EE",
    },
    description: {
        fontWeight: "normal",
        fontSize: 20,
        color: "#6C7689",
        marginTop: 16
    },
    subTitle: {
        fontSize: 20,
        color: "#454D5F",
        marginLeft: 16
    },
    subContainer: {
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        padding: "12px 24px",
        marginTop: 24,
        marginBottom: 30,
        "&:last-child": {
            marginBottom: 0,
        }
    },
    item: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 16,
        "&:last-child": {
            marginBottom: 0
        },
        "& > .MuiTypography-root": {
            "&:first-child": {
                fontSize: 16,
                color: "#454D5F"
            },
            "&:last-child": {
                fontSize: 20,
                color: "#003366"
            },
        }
    },
    tableHead: {
        backgroundColor: '#e8ecf4'
    },
    tableContainer: {
        border: "1px solid #DDE3EE",
        borderRadius: 20,
        /*padding: "12px 24px",*/
        marginTop: 24,
        marginBottom: 30,
        "&:last-child": {
            marginBottom: 0,
        },

    },
    tableBody: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#F7F8FB",
        },
    },
    subTableRow: {
        textTransform: 'uppercase',

        '& .MuiTableCell-root': {
            '& .MuiTypography-root ': {
                fontSize: 10
            }

        }
    },
    valueInNorm: {
        color: '#123262'
    },
    valueOutOfNorm: {
        color: '#933832'
    }
}));

const Detail = ({onBack}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [data, setData] = useState(null);
    const history = useHistory();

    const getClassNameByRange = (value, range, sex = 'male') => {
        if (range.min?.male && range.min?.female) {
            return value >= range.min[sex] && value <= range.max[sex] ? classes.valueInNorm : classes.valueOutOfNorm;
        } else if (range['<']) {
            return value < range['<'] ? classes.valueInNorm : classes.valueOutOfNorm;
        } else {
            return value >= range.min && value <= range.max ? classes.valueInNorm : classes.valueOutOfNorm;
        }
    };

    const getRefValue = (range, sex = 'male') => {
        if (range.min?.male && range.min?.female) {
            return range.min[sex] + ' - ' + range.max[sex];
        } else if (range['<']) {
            return '< ' + range['<'];
        } else {
            return range.min + ' - ' + range.max;
        }
    };

    const {selectedProbeId} = useSelector(state => state.card);

    const unitsRef = {
        'alt' : 'U/l', 'ast' : 'U/l', 'creatine' : 'mg/dl', 'bilirubine' : 'mg/dl'
    };

    useEffect(() => {
        API.card.probe.show(selectedProbeId?.id).then(res => {
            setData(res.data.data);
        });
    }, []);

    return (
        !data ? <LinearProgress/> : <>
            <Box display="flex" alignItems="center" mb={2}>

                <Button variant="outlined" className={classes.backButton} onClick={onBack}>
                    <img src={BackIcon} alt="Go back"/>
                </Button>

                <Title className={classes.goBack}>{t('common.go_back')}</Title>
            </Box>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Title>{t('pages.patient.probes.detail')} {t('pages.patient.probes.probe')} nr: {data?.code}</Title>
                    <Title className={classes.description}>
                        {t('pages.patient.probes.create_date')}: {' '}
                        {moment(data?.updated_at).format('DD.MM.YYYY, HH:mm')}
                    </Title>
                </Box>
                <Box>
                    <Title className={classes.subTitle}>{t('pages.patient.probes.basic_data')}</Title>
                    <Box className={classes.subContainer}>
                        {data?.ordered_by_firstname && <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.order')}</Typography>
                            <Typography>{data?.ordered_by_firstname} {data?.ordered_by_surname}</Typography>
                        </Box>}

                        {data?.laboratory && <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.laboratory_address')}</Typography>
                            <Typography>
                                {data?.laboratory?.address}, {data?.laboratory?.postcode} {data?.laboratory?.city}
                            </Typography>
                        </Box>}
                        <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.status')}</Typography>
                            <Typography>{t(`pages.patient.probes.${data?.status}`)}</Typography>
                        </Box>
                    </Box>

                    <Title className={classes.subTitle}>{t('pages.patient.probes.additional_data')}</Title>
                    <Box className={classes.subContainer}>
                        <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.cito')}</Typography>
                            <Typography>{t(data?.is_cito ? 'common.yes' : 'common.no')}</Typography>
                        </Box>
                        {data?.type_of_test && <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.type_of_test')}</Typography>
                            <Typography>{t(`pages.patient.probes.${data?.type_of_test}`)}</Typography>
                        </Box>}
                        <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.type_of_material')}</Typography>
                            <Typography>{t(`pages.patient.probes.${data?.type_of_material}`)}</Typography>
                        </Box>
                        {data?.carrier && <Box className={classes.item}>
                            <Typography>{t('probes.carrier_name')}</Typography>
                            <Typography>{t(data?.carrier?.name)}</Typography>
                        </Box>}

                        {data?.ordered_at != '0000-00-00 00:00:00' && <Box className={classes.item}>
                            <Typography>{t('probes.ordered_at')}</Typography>
                            <Typography>{moment(data?.ordered_at).format('DD.MM.YYYY')}</Typography>
                        </Box>}
                        {data?.date_of_sample != '0000-00-00 00:00:00' && <Box className={classes.item}>
                            <Typography>{t('probes.additional_date_of_sample')}</Typography>
                            <Typography>{moment(data?.date_of_sample).format('DD.MM.YYYY')}</Typography>
                        </Box>}
                        <Box className={classes.item}>
                            <Typography>{t('pages.patient.probes.code')}</Typography>
                            <Typography>{data?.code}</Typography>
                        </Box>
                    </Box>
                    {data?.biochemistry_results.length > 0 && <><Title
                        className={classes.subTitle}>{t('pages.patient.probes.biochemistry_results')}</Title>
                        <TableContainer className={classes.tableContainer}>
                            <Table>
                                <TableHead className={classes.tableHead}>
                                    <TableRow className={classes.subTableRow}>
                                        <TableCell key="2">
                                            <Typography>Badanie</Typography>
                                        </TableCell>
                                        <TableCell key="2">
                                            <Typography>Wartość</Typography>
                                        </TableCell>
                                        <TableCell key="2">
                                            <Typography>Wartości ref.</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(data?.biochemistry_results[0]).filter(key => ['alt', 'ast', 'creatine', 'bilirubine'].includes(key)).map((key) => {
                                        return (<TableRow className={classes.tableBody} key={key}>
                                            <TableCell>
                                                <Typography>{t('pages.patient.probes.results.' + key)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    className={getClassNameByRange(data?.biochemistry_results[0][key], data?.concentration_range[key])}>{data?.biochemistry_results[0][key]}
                                                     <small> [{unitsRef[key]}]</small></Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <Typography>{getRefValue(data?.concentration_range[key])}
                                                        <small> [{unitsRef[key]}]</small></Typography>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                    {data?.concentrations_results.length > 0 &&
                        <>
                            <Title
                                className={classes.subTitle}>{t('pages.patient.probes.drug_concentration_results')}</Title>
                            <TableContainer className={classes.tableContainer}>
                                <Table>
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Kod próby: {data.code}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <Typography style={{marginRight: 16}}>
                                                        Liczba godzin: {parseFloat(data.concentrations_results[0].hours).toFixed(2)}
                                                    </Typography>
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title="Liczba godzin jaka minęła od ostatniej dawki leku do momentu pobrania krwi"
                                                        aria-label="Liczba godzin jaka minęła od ostatniej dawki leku do momentu pobrania krwi"
                                                    >
                                                        <img src={InfoIcon} alt="Info"/>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Data
                                                    wykonania: {data.concentrations_results[0].date_of_analysis}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.subTableRow}>
                                            <TableCell>
                                                <Typography>BADANY LEK</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Stężenie</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Wartości ref.</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(data?.concentrations_results[0].concentrations).map((key) => {
                                            return (<TableRow className={classes.tableBody} key={key}>
                                                <TableCell>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography>{key}</Typography>
                                                        <IconButton
                                                            component="a"
                                                            color="primary"
                                                            variant="outlined"
                                                            target="_blank"
                                                            href={Constants.getFormulaLink(key)}
                                                        >
                                                            <img src={ChemicalFormulaImage} alt="Chemical Formula"
                                                                 style={{width: 32}}/>
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        className={getClassNameByRange(data?.concentrations_results[0].concentrations[key], data?.concentration_range?.[key])}>{data?.concentrations_results[0].concentrations[key]}
                                                        <small> [ng/ml]</small></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {data?.concentration_range?.[key] &&
                                                        <Typography>{data?.concentration_range?.[key].min} - {data?.concentration_range?.[key].max}
                                                            <small> [ng/ml]</small></Typography>}
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </Box>
            </Box>
        </>
    );
};

export default Detail;
