import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography, Divider, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

import BackIcon from "assets/images/icons/back.svg";
import Probes from "./Probes";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 32,
    color: "#003366",
  },
  buttonLabel: {
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    textTransform: 'none',
  },
  dateText: {
    marginTop: theme.spacing(2),
    fontFamily: "Lato",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#6C7689"
  },
  subTitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#454D5F",
    marginLeft: 24,
    marginBlock: 24,
  },
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 2.5)
  },
  label: {
    fontFamily: "Lato",
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
    color: "#454D5F",
  },
  value: {
    fontFamily: "Lato",
    fontSize: theme.spacing(2.5),
    lineHeight: 1.4,
    color: "#003366",
  },
  backButton: {
    "& img": {
      width: theme.spacing(5),
      height: theme.spacing(5),
      padding: 10,
      marginRight: 14,
      border: "1px solid #DDE3EE",
      borderRadius: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: 1.5,
      color: "#003366"
    }
  },
}));

const TextGroup = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};

const Detail = ({ onBack, showSummary, onShowProbe }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const purposes = {
    home: 'Do samodzielnego pobrania w domu',
    clinic: 'Do wykorzystania na wizycie'
  };
  const { selectedSetId } = useSelector(state => state.card);

  const [selectedSet, setSelectedSet] = useState({
    doctor: { firstname: '', surname: '' },
    patient: { firstname: '', surname: '' },
    street: '',
    building: '',
    postcode: '',
    city: '',
    created_at: '',
    purpose: '',
    status: { title: '' },
    probes: [],
  });

  useEffect(() => {
    if(!selectedSetId) return;

    API.card.sets.show(selectedSetId).then(res => {
      setSelectedSet(res.data.data);
    });
  }, [selectedSetId]);

  return (
    <Box style={{'display':'block'}}>
      {onBack && <Button className={classes.backButton} onClick={onBack}>
        <Box display="flex" alignItems="center">
          <img src={BackIcon} alt="go back" />
          <Typography>{t('pages.patient.sets.goBack')}</Typography>
        </Box>
      </Button>}

      {showSummary && <><Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
        <Box>
          <Typography className={classes.title}>
            {t("pages.patient.sets.detailTitle")}
          </Typography>
          <Typography className={classes.dateText}>
            {t('pages.patient.sets.createdDate')}: {selectedSet.created_at ? moment(selectedSet.created_at).format('DD.MM.YYYY, HH:mm') : '-'}
          </Typography>
        </Box>
        {/*<PrimaryButton>
          <ButtonLabel>{t('pages.patient.sets.sendSet')}</ButtonLabel>-->
        </PrimaryButton>*/}
      </Box>
      <Divider />
      <Typography className={classes.subTitle}>
        {t('pages.patient.sets.basicData')}
      </Typography>
      <Box className={classes.card}>

        <TextGroup
          label={t('pages.patient.sets.patient')}
          value={`${selectedSet.patient.firstname} ${selectedSet.patient.surname}`}
        />
        <TextGroup
          label={t('pages.patient.sets.purpose')}
          value={purposes[selectedSet.purpose]}
        />
        <TextGroup
          label="Status"
          value={selectedSet.status?.title}
        />
      </Box>
      <Typography className={classes.subTitle} >
        {t('pages.patient.layout.samples')}
      </Typography></>}
      <Probes defaultProbes={selectedSet.probes} selectedSet={selectedSet} onShowProbe={onShowProbe}/>
    </Box>
  );
};

export default Detail;
