import React, {useState} from "react";
import {Box, Button, CircularProgress, Dialog, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import EcgChart from "./EcgChart";
import ScrollContainer from 'react-indiana-drag-scroll'


const useStyles = makeStyles(theme => ({
    chart : {
        marginTop: 10,
        marginBottom: 10,
        overflowX: 'auto',
    }
}));

const MultiEcgChart = ({ children, ecgData, className, ...rest }) => {
    const classes = useStyles();

    let dataForCharts = [];
    ecgData?.VitalSigns?.TwelveLeadReport?.Waveforms?.Waveform?.filter((el, idx) => {

        if (["ECG_AVL", "ECG_I", "ECG_AVR_NEG", "ECG_II", "ECG_AVF", "ECG_III", "ECG_V1", "ECG_V2", "ECG_V3", "ECG_V4", "ECG_V5", "ECG_V6"]
            .includes(el["@attributes"].Type)) {

            dataForCharts.push({
                title: el["@attributes"].Type,
                data: el.WaveformSegment.Data.split(",").map(el => {
                    return parseInt(el);
                })
            });
        }
    });


    return (<ScrollContainer >{dataForCharts.map((el, idx) => {
        return (<EcgChart key={idx} ecgData={el}/>)
    })}</ScrollContainer>);
};

export default MultiEcgChart;
