import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import * as Actions from "store/actions/CardAction";
import moment from "moment";

import ArrowRightImage from "assets/images/icons/arrow-right.svg";
import ArrowLeftImage from "assets/images/icons/arrow-left.svg";
import CalendarImage from "assets/images/pages/patient/calendar.png";
import PlusIcon from "assets/images/icons/plus.svg";
import CloseIcon from "assets/images/icons/close.svg";
import API from "apis/API";
import Constants from "utils/Constants";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-start",
    },
    "& .MuiBackdrop-root": {
      background: "none",
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
      margin: 0,
      position: "relative",
      background: "#FFF",
      width: "100%",
      maxWidth: 1146,
      "& .MuiDialogTitle-root": {
        padding: "24px 16px 20px",
        "& > h2": {
          fontFamily: "Lato",
          fontWeight: 700,
          fontSize: 20,
          color: "#003366",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "none",
        padding: "0 16px",
        "& > .MuiGrid-container": {
          width: "auto",
        },
      },
      "& .MuiDialogActions-root": {
        padding: "30px 16px 32px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      margin: 32,
      "& .MuiBackdrop-root": {
        background: "rgba(0, 0, 0, 0.5)",
      },
      "& .MuiDialog-scrollPaper": {
        alignItems: "center",
      },
      "& .MuiPaper-root": {
        background: "#F7F8FB",
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        "& .MuiDialogTitle-root": {
          padding: "24px 32px 20px",
        },
      },
      "& .MuiDialogContent-root": {
        padding: "0 32px",
        overflowY: "auto",
      },
      "& .MuiDialogActions-root": {
        padding: "30px 32px 32px",
      },
    },
  },
  cancelButton: {
    display: "none",
    width: 140,
    height: 56,
    borderRadius: 8,
    border: "1px solid #A6AEBF",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  saveButton: {
    marginLeft: "0px!important",
    height: 56,
    borderRadius: 8,
    border: "1px solid #A6AEBF",
    background: "#003366",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#FFF",
    width: "100%",
    "&:hover": {
      background: "#003366",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "16px!important",
      display: "flex",
      width: 140,
    },
  },
  calendar: {
    width: "100%!important",
    padding: 0,
    border: "none",
    background: "#FFFFFF",
    borderRadius: 0,
    paddingBottom: 24,
    borderBottom: "1px solid #DDE3EE",
    [theme.breakpoints.up("lg")]: {
      padding: 16,
      borderRadius: 8,
      border: "1px solid #DDE3EE",
    },
  },
  tile: {
    padding: 1,
  },
  tileContent: {
    background: "#FFFFFF",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: "12px 0",
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: 700,
    color: "#A6AEBF",
    transition: "0.1s",
    "&:hover": {
      opacity: 0.3,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "24px 0",
    },
  },
  weekendTileContent: {
    background: "#F7F8FB!important",
  },
  activeTileContent: {
    background: "#0066CC!important",
    color: "#FFF",
  },
  navigationButton: {
    minWidth: "32px!important",
    width: 32,
    height: 32,
    borderRadius: 8,
    background: "#F7F8FB!important",
    border: "1.5px solid #DDE3EE!important",
  },
  card: {
    height: "100%",
    background: "#FFF!important",
    border: "none",
    borderRadius: 8,
    boxShadow: "none!important",
    [theme.breakpoints.up("lg")]: {
      border: "1px solid #DDE3EE",
    },
  },
  cardHeader: {
    padding: 0,
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    borderBottom: "none",
    color: "#003366",
    paddingTop: 24,
    [theme.breakpoints.up("lg")]: {
      borderBottom: "1px solid #DDE3EE",
      padding: "16px 24px!important",
      paddingTop: 0,
      fontSize: 24,
    },
  },
  cardBody: {
    padding: "24px 0",
    [theme.breakpoints.up("lg")]: {
      padding: 24,
      overflowY: "auto",
      maxHeight: 432,
    },
  },
  ticket: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #3399FF",
    borderRadius: 8,
    minHeight: 100,
    background: "#E6F2FF",
    marginBottom: 8,
    cursor: "pointer",
  },
  normalTicket: {
    "& img": {
      marginBottom: 10,
    },
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
    },
  },
  markedTicket: {
    background: "#0066CC",
    border: "none",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#FFF",
      "&:first-child": {
        fontSize: 20,
        marginBottom: 4,
      },
      "&:last-child": {
        fontSize: 16,
      },
    },
  },
  activeTicket: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 24px",
    background: "#FFF",
    border: "1px solid #DDE3EE",
    "& img": {
      width: 32,
      height: 32,
      marginRight: 24,
    },
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#003366",
      "&:first-child": {
        fontSize: 20,
        marginBottom: 4,
      },
      "&:last-child": {
        fontSize: 16,
      },
    },
  },
  closeButton: {
    minWidth: 32,
    width: 32,
    height: 32,
    borderRadius: 8,
    background: "#FFFFFF",
    border: "1.33333px solid #DDE3EE",
    position: "absolute",
    top: 24,
    right: 32,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
}));

const CreateAppointmentModal = ({ open, handleClose, ...rest }) => {
  const { selectedPatientID, visitsBySelectedDate, selectedVisit, selectedPatient } =
    useSelector((state) => state.card);
  const classes = useStyles();
  const [date, onChangeDate] = useState(
    selectedVisit ? new Date(selectedVisit.date) : new Date()
  );
  useEffect(() => {
    onChangeDate(selectedVisit ? new Date(selectedVisit.date) : new Date());
  }, [selectedVisit]);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const [meetingTimes, setMeetingTimes] = useState();
  const [selectedTime, setSelectedTime] = useState({ start: null, end: null });

  const startAt = process.env.REACT_APP_VISIT_START_HOUR * 1;
  const endAt = process.env.REACT_APP_VISIT_END_HOUR * 1;
  const meetingTime = process.env.REACT_APP_VISIT_MEETING_MINUTES * 1;

  const getMeetingTimes = (startTime, endTime, period) => {
    return visitsBySelectedDate?.timeTable;
  };

  const getVisitInfo = (startTime) => {
    let returnVal = null;
    visitsBySelectedDate?.data?.map((visit) => {
      if (visit.started_at === startTime) {
        returnVal = visit;
      }
    });

    return returnVal;
  };

  const handleSave = () => {
    if (!selectedTime.start || !selectedTime.end)
    {
      return;
    }

    setIsSaving(true);

    if (selectedVisit) {
      API.card.visit
        .update(selectedVisit.id, {
          date: moment(date).format("YYYY-MM-DD"),
          started_at: selectedTime.start,
          ended_at: selectedTime.end,
          patient_id: selectedPatientID,
        })
        .then((res) => {
          handleClose();
          setIsSaving(false);
          setSelectedTime({ start: null, end: null });
          if (res.data?.visit)
          {
            dispatch(Actions.updateVisits(res.data.visit));
            dispatch(Actions.getVisitsBySelectedDate(moment(date).format("YYYY-MM-DD")));
          }

        });
    } else {

      API.card.visit
        .create({
          date: moment(date).format("YYYY-MM-DD"),
          started_at: selectedTime.start,
          ended_at: selectedTime.end,
          patient_id: selectedPatientID,
        })
        .then((res) => {

          handleClose();
          setIsSaving(false);
          setSelectedTime({ start: null, end: null });


          if (res.data?.visit)
          {
            dispatch(Actions.addVisits(res.data.visit));
            dispatch(Actions.getVisitsBySelectedDate(moment(date).format("YYYY-MM-DD")));
          }
        });
    }
  };

  const isWeekend = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };
  const today = new Date();
  const todayStartPoint = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  useEffect(() => {
    setMeetingTimes(
        getMeetingTimes(
            { hour: startAt, minute: 0 },
            { hour: endAt, minute: 0 },
            meetingTime
        )
    );
  }, [visitsBySelectedDate]);

  useEffect(() => {
    setSelectedTime({ start: null, end: null });
    dispatch(
      Actions.getVisitsBySelectedDate(moment(date).format("YYYY-MM-DD"))
    );
  }, [date]);

  const getTileContent = (date) => {
    return (
      <Box
        className={`${classes.tileContent} ${
          date.getTime() === todayStartPoint.getTime()
            ? classes.activeTileContent
            : ``
        } ${isWeekend(date) ? classes.weekendTileContent : ``}`}
      >
        {date.getDate()}
      </Box>
    );
  };

  const NextLabel = () => {
    return (
      <Button className={classes.navigationButton}>
        <img src={ArrowRightImage} alt=":( Not Found" />
      </Button>
    );
  };

  const PrevLabel = () => {
    return (
      <Button className={classes.navigationButton}>
        <img src={ArrowLeftImage} alt=":( Not Found" />
      </Button>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="lg"
      className={classes.dialog}
      {...rest}
    >
      <Button className={classes.closeButton} onClick={handleClose}>
        <img src={CloseIcon} alt=":( Not Found" />
      </Button>
      <DialogTitle>Umawianie wizyty</DialogTitle>
      <DialogContent>
        <Grid container style={{ margin: "0 -8px" }}>
          <Grid
            item
            xs={12}
            lg={6}
            style={{ padding: "0 8px" }}
            className="calendar"
          >
            <Calendar
              formatDay={() => null}
              onChange={onChangeDate}
              value={date}
              className={classes.calendar}
              locale="pl-PL"
              tileClassName={classes.tile}
              tileContent={({ date, activeStartDate }) =>
                getTileContent(date, activeStartDate)
              }
              tileDisabled={({ activeStartDate, date, view }) =>
                date.getDay() === 0 ||
                date.getDay() === 6 ||
                date.getTime() < todayStartPoint.getTime()
              }
              nextLabel={<NextLabel />}
              prevLabel={<PrevLabel />}
            />
          </Grid>
          <Grid item xs={12} lg={6} style={{ padding: "0 8px" }}>
            <Card className={classes.card}>
              <Box className={classes.cardHeader}>
                {Constants.days[moment(date).format("dddd")]},{" "}
                {moment(date).format("DD.MM.YYYY")}
              </Box>
              <Box className={classes.cardBody}>
                {meetingTimes?.length == 0 &&
                    <>
                      <Typography>
                        Brak wolnych terminów w tym dniu
                      </Typography>
                    </>
                }
                {meetingTimes?.map((time, index) => {
                  return (
                    <Box
                      key={index}
                      className={`${classes.ticket} ${
                        time === selectedTime
                          ? classes.markedTicket
                          : time.visit
                          ? classes.activeTicket
                          : classes.normalTicket
                      }`}
                      onClick={() =>
                        !time.visit && setSelectedTime(time)
                      }
                    >
                      {time === selectedTime ? (
                        <>
                          <Typography>Zaznaczono!</Typography>
                          <Typography>
                            {time.start.slice(0, -3)} - {time.end.slice(0, -3)}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {time.visit ? (
                            <>
                              <img src={CalendarImage} alt=":( Not Found" />
                              <Box>
                                <Typography className={classes.name}>
                                  {time.visit?.patient?.firstname}{" "}
                                  {time.visit?.patient?.surname}
                                </Typography>
                                <Typography>
                                  {time.start.slice(0, -3)} -{" "}
                                  {time.end.slice(0, -3)}
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              <img src={PlusIcon} alt=":( Not Found" />
                              <Typography>
                                {time.start.slice(0, -3)} -{" "}
                                {time.end.slice(0, -3)}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          className={classes.cancelButton}
        >
          Anuluj
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          className={classes.saveButton}
        >
          {isSaving ? (
            <CircularProgress size={30} style={{ color: "#FFF" }} />
          ) : (
            <>Umów</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAppointmentModal;
