import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
  Select,
  MenuItem,
  Card,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TextInput from "components/form/TextInput";
import Switch from "components/form/Switch";
import CloseIcon from "assets/images/icons/close.svg";
import * as Actions from "store/actions/CardAction";
import * as PatientActions from "store/actions/PatientAction";
import ProbeTicket from "./ProbeTicket";
import API from "apis/API";
import Validator, { Required, NotEmptyArray, validate } from "utils/Validator";
import Storage from "utils/Storage";
import SampleImage from "assets/images/pages/patient/sample.png";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      minHeight: "auto",
    },
  },
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#454D5F",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
    },
  },
  addButton: {
    alignSelf: "flex-end",
    height: 56,
    marginBottom: 10,
    background: "#003366",
    borderRadius: 8,
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    padding: "0 34px",
    textTransform: "none",
    position: "absolute",
    bottom: 100,
    left: 0,
    right: 0,
    width: "100%",
    "&:hover": {
      background: "#003366",
    },
    [theme.breakpoints.up("lg")]: {
      position: "relative",
      left: "auto",
      right: "auto",
      width: 280,
      bottom: "auto",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 477,
    },
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(1),
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  dialogBody: {
    padding: "20px 32px 0",
  },
  dialogFooter: {
    padding: "0 32px 40px",
    "& .MuiButton-root": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 56,
      fontWeight: 700,
      fontWize: 16,
      textTransform: "none",
      borderRadius: 8,
      color: "#003366",
      border: "1px solid #A6AEBF",
    },
  },
  sendButton: {
    background: "#003366",
    color: "#FFF!important",
    border: "none!important",
    "&:hover": {
      background: "#003366",
    },
  },
  labelTxt: {
    fontSize: 14,
    color: "#6C7689",
    marginBottom: 2,
  },
  selectBox: {
    marginBottom: 16,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      border: "1px solid #A6AEBF",
    },
  },
  groupTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: "#454D5F",
    marginBottom: 18,
  },
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxShadow: "none",
    marginBottom: 24,
    background: "#F7F8FB",
    "& > .MuiBox-root": {
      marginBottom: 20,
      "& img": {
        width: 29,
        marginRight: 30,
      },
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontFamily: "Lato",
        fontWeight: 700,
      },
    },
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
      padding: theme.spacing(3, 4),
      flexDirection: "row",
      alignItems: "center",
      "& > .MuiBox-root": {
        marginBottom: 0,
        "& img": {
          width: "45px!important",
          marginRight: "38px!important",
        },
      },
    },
  },
  name: {
    fontSize: 20,
    color: "#003366",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
  date: {
    fontWeight: "400!important",
    fontSize: 14,
    color: "#454D5F",
    marginBottom: 0,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      fontWeight: "700!important",
      marginBottom: 8,
    },
  },
  description: {
    display: "none",
    fontSize: 16,
    color: "#003366",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  trackButton: {
    width: "100%",
    height: 56,
    border: "2px solid #003366",
    borderRadius: 8,
    textTransform: "none",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
      marginLeft: 16,
    },
    [theme.breakpoints.up("lg")]: {
      width: 217,
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 1118,
      maxWidth: 1118,
    },
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(1),
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  dialogBody: {
    padding: theme.spacing(0, 4, 10),
  },
  table: {
    "& td": {
      fontSize: 20,
    },
  },
  marginLinear: {
    marginTop: 20,
  }
}));

const List = ({ onShowDetail }) => {
  const { carriers, medicines, selectedPatient, selectedPatientID, isTabLoading } =
    useSelector((state) => {
      return {
        selectedPatientID: state.card.selectedPatientID,
        selectedPatient: state.card.selectedPatient,
        medicines: state.patient.medicineList,
        carriers: state.card.carrierList,
        isTabLoading: state.card.isTabLoading,
      };
    }, shallowEqual);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [errors, setErrors] = useState(null);
  const [code, setCode] = useState("");

  const [formData, setFormData] = useState({
    medicine_ids: [],
    date_of_sample: "",
    carrier_id: "",
    is_cito: true,
    type_of_test: "biochemistry",
    type_of_material: "venous",
    ordered_by_firstname: JSON.parse(Storage.get("user")).doctor.firstname,
    ordered_by_surname: JSON.parse(Storage.get("user")).doctor.surname,
    ordered_at: "",
    patient_firstname: selectedPatient?.firstname,
    patient_surname: selectedPatient?.surname,
    patient_address_street: selectedPatient?.address_street,
    patient_address_building: selectedPatient?.address_building,
    patient_address_postcode: selectedPatient.address_postcode,
    patient_address_city: selectedPatient?.address_city,
    code: selectedPatient
      ? `${`000${selectedPatient.id}`.slice(-3)}${
          selectedPatient.probes_count + 1
        }`
      : "",
    patient_id: selectedPatient.id,
  });

  const testTypes = [
    {
      id: "biochemistry",
      name: "Biochemia",
    },
    {
      id: "drug-concentration",
      name: "Stężenie leków",
    },
  ];

  const materialTypes = [
    {
      id: "venous",
      name: "Żylna",
    },
    {
      id: "capillary",
      name: "Włośniczkowa",
    },
  ];

  const Validators = {
    medicine_ids: new Validator(NotEmptyArray),
    date_of_sample: new Validator(Required),
    carrier_id: new Validator(Required),
    ordered_by_firstname: new Validator(Required),
    ordered_by_surname: new Validator(Required),
    ordered_at: new Validator(Required),
    patient_firstname: new Validator(Required),
    patient_surname: new Validator(Required),
    patient_address_street: new Validator(Required),
    patient_address_building: new Validator(Required),
    patient_address_postcode: new Validator(Required),
    patient_address_city: new Validator(Required),
  };

  const handleChangeFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "is_cito" ? e.target.checked : e.target.value,
    });
  };

  const handleChangeSwitch = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowDetail = (probe) => () => {
    dispatch(Actions.selectProbe(probe)).then(() => {
      onShowDetail();
    });
  };

  useEffect(() => {
    dispatch(Actions.getCarrierList());
    dispatch(PatientActions.getMedicineList());
    dispatch(Actions.getSelectedPatientProbes(selectedPatientID));
  }, []);

  useEffect(() => {
    API.card.probe.generateCode(formData).then((res) => {
      if (res.data?.code != "") {
        setCode(res.data.code);
      }
    });
  }, [formData, open]);

  const handleSend = () => {
    let _errors = validate(formData, Validators);
    setErrors(_errors);
    if (_errors) {
      addToast(t("common.validation_error"), { appearance: "error" });
      return;
    }

    setIsSaving(true);

    API.card.probe.create(formData).then((res) => {
      setIsSaving(false);
      dispatch(Actions.updateProbes(res.data.probe));
      handleClose();
    });
  };

  const handleRedirect = () => {
    window.open("https://e-nadawca.poczta-polska.pl/nadaj/home/", "_blank");
  };

  return (
    <>
      {isTabLoading && <LinearProgress className={classes.marginLinear} />}
      {!isTabLoading && (
        <>
          <Box className={classes.root}>
            <Typography className={classes.title}>
              {t("pages.patient.samples.title")}
            </Typography>
            {selectedPatient?.probes?.map((sample, index) => {
              return sample.status == "received" ? (
                <Card className={classes.card} key={index}>
                  <Box>
                    <img src={SampleImage} alt=":( Not Found" />
                    <Box>
                      <Typography className={classes.name}>
                        Próbka nr {sample.code}
                      </Typography>
                      <Typography className={classes.date}>
                        {t("pages.patient.probes.statuses." + sample.status)}
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    onClick={handleShowDetail(sample)}
                    className={classes.trackButton}
                  >
                    Szczegóły
                  </Button>
                </Card>
              ) : (
                <></>
              );
            })}
            {/*<Button className={classes.addButton} onClick={() => setOpen(true)}>
            {t("pages.patient.samples.add")}
          </Button>
          <Button className={classes.addButton} onClick={handleRedirect}>
            {t("pages.patient.samples.order")}
          </Button>*/}
          </Box>
          <Dialog onClose={handleClose} className={classes.dialog} open={open}>
            <Box className={classes.dialogHeader}>
              <Typography>Wyślij próbkę do analizy</Typography>
              <Button onClick={handleClose} className={classes.closeButton}>
                <img src={CloseIcon} alt=":( Not Found" />
              </Button>
            </Box>
            <Box className={classes.dialogBody}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.labelTxt}>
                    Leki do analizy
                  </Typography>
                  <Select
                    value={formData.medicine_ids}
                    multiple
                    fullWidth
                    name="medicine_ids"
                    onChange={(e) => handleChangeFormData(e)}
                    variant="outlined"
                    className={classes.medicine}
                    error={!!errors?.medicine_ids}
                  >
                    {medicines.map((medicine) => {
                      return (
                        <MenuItem key={medicine.id} value={medicine.id}>
                          {medicine.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.date_of_sample}
                    name="date_of_sample"
                    onChange={(e) => handleChangeFormData(e)}
                    type="datetime-local"
                    label="Data pobrania próbki"
                    error={!!errors?.date_of_sample}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Typ badania
                  </Typography>
                  <Switch
                    items={testTypes}
                    onChange={(e) => handleChangeSwitch("type_of_test", e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Rodzaj materiału
                  </Typography>
                  <Switch
                    items={materialTypes}
                    onChange={(e) => handleChangeSwitch("type_of_material", e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelTxt}>
                    Przewoźnik
                  </Typography>
                  <Select
                    value={formData.carrier_id}
                    fullWidth
                    name="carrier_id"
                    onChange={(e) => handleChangeFormData(e)}
                    variant="outlined"
                    className={classes.selectBox}
                    error={!!errors?.carrier_id}
                  >
                    {carriers.map((carrier) => {
                      return (
                        <MenuItem key={carrier.id} value={carrier.id}>
                          {carrier.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={formData.is_cito}
                        name="is_cito"
                        onChange={(e) => handleChangeFormData(e)}
                      />
                    }
                    label="CITO"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Dane zlecającego analizę próbki
                  </Typography>
                  <TextInput
                    value={formData.ordered_by_firstname}
                    name="ordered_by_firstname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Imię (Imiona)*"
                    error={!!errors?.ordered_by_firstname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.ordered_by_surname}
                    name="ordered_by_surname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Nazwisko*"
                    error={!!errors?.ordered_by_surname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.ordered_at}
                    name="ordered_at"
                    onChange={(e) => handleChangeFormData(e)}
                    type="datetime-local"
                    label="Data zlecenia*"
                    error={!!errors?.ordered_at}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Dane pacjenta(wczytane z karty)
                  </Typography>
                  <TextInput
                    value={formData.patient_firstname}
                    name="patient_firstname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Imię (Imiona)*"
                    error={!!errors?.patient_firstname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.patient_surname}
                    name="patient_surname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Nazwisko*"
                    error={!!errors?.patient_surname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.patient_address_street}
                    name="patient_address_street"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Ulica*"
                    error={!!errors?.patient_address_street}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={formData.patient_address_building}
                    name="patient_address_building"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Budynek/lokal*"
                    error={!!errors?.patient_address_building}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={formData.patient_address_postcode}
                    name="patient_address_postcode"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Kod pocztowy*"
                    error={!!errors?.patient_address_postcode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.patient_address_city}
                    name="patient_address_city"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Miasto*"
                    error={!!errors?.patient_address_city}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Wygenerowany kod próbki
                  </Typography>
                  <TextInput value={code} disabled />
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.dialogFooter}>
              <Grid container spacing={3}>
                <Grid xs={6} item>
                  <Button onClick={handleClose}>{t("common.cancel")}</Button>
                </Grid>
                <Grid xs={6} item>
                  <Button className={classes.sendButton} onClick={handleSend}>
                    {isSaving ? (
                      <CircularProgress size={30} style={{ color: "#FFF" }} />
                    ) : (
                      <>{t("common.send")}</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
};

export default List;
