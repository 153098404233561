import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../store/actions/CardAction";

function ToggleContainer({Detail, List}) {
    const dispatch = useDispatch();
    const {selectedSubTab} = useSelector((state) => state.card);

    return (
        selectedSubTab?.toggle
            ? <Detail onBack={() => dispatch(Actions.setSubTab({...selectedSubTab, toggle: false}))}/>
            : <List onShowDetail={() => dispatch(Actions.setSubTab({...selectedSubTab, toggle: true}))}/>
    );
}

export default ToggleContainer;
