import React, {useEffect, useState} from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import API from "apis/API";
import Document from "components/documents/Document";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        background: "#FFF",
        minHeight: "100vh",
        "& > .MuiBox-root": {
            width: "100%",
            maxWidth: 1017,
            padding: 0,
            margin: "0 16px",
        },
        [theme.breakpoints.up("lg")]: {
            background: "none",
            "& > .MuiBox-root": {
                padding: "32px 0",
                margin: "0 32px",
            },
        },
    },
    pageTitle: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 20,
        color: "#003366",
        margin: "24px 0",
        letterSpacing: -2,
        [theme.breakpoints.up("lg")]: {
            margin: 0,
            marginBottom: 16,
            fontSize: 32,
        },
    },
    card: {
        background: "#FFFFFF",
        padding: "0",
        [theme.breakpoints.up("lg")]: {
            borderRadius: 8,
            border: "1px solid #DDE3EE",
            padding: "38px 48px 18px",
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 20,
        color: "#454D5F",
        marginBottom: 42,
        display: "none",
        [theme.breakpoints.up("lg")]: {
            display: "flex",
        },
    },
}));

const Documents = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        API.settings.documents().then(res => setDocuments(res.data.data));
    }, []);

    return (
        <Box className={classes.root}>
            <Box>
                <Typography className={classes.pageTitle}>
                    {t("pages.document.title")}
                </Typography>
                <Box className={classes.card}>
                    <Typography className={classes.title}>
                        {t("pages.document.subtitle")}
                    </Typography>
                    {documents.map((document, index) => {
                        return <Document {...document} key={index}/>;
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default Documents;
