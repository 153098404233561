import React, { useState } from "react";
import { Box, Button, CircularProgress, Dialog, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "../../assets/images/icons/close.svg";

const useStyles = makeStyles(theme => ({
    deleteButton: {
        width: 32,
        minWidth: 32,
        height: 32,
        background: "#D26360",
        borderRadius: 8,
        "&:hover": {
            background: "#D26360",
        },
        "& img": {
            width: 12.5,
            height: 12.5,
            filter: "brightness(0) invert(1)",
        },
    },
    removeModal: {
        "& .MuiPaper-root": {
            width: 600,
            padding: theme.spacing(3, 4, 4),
            "& .MuiBox-root": {
                "&:first-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                },
                "&:last-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                },
            },
            "& .MuiTypography-root": {
                padding: theme.spacing(5, 0),
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 16,
                color: "#454D5F",
            },
        },
    },
    yesBtn: {
        width: 140,
        height: 56,
        background: "#D26360",
        borderRadius: 8,
        color: "#FFF",
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
        marginLeft: theme.spacing(3),
        "&:hover": {
            background: "#D26360",
        },
    },
    noBtn: {
        width: 140,
        height: 56,
        background: "#FFF",
        border: "1px solid #003366",
        borderRadius: 8,
        color: "#003366",
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
    },
}));

const DeleteButton = React.forwardRef(({ children, className, onClickHandler, skipNativeClass, hint, ...rest }, ref) => {
    const classes = useStyles();
    const [removeModalOpen, setRemoveModalOpen] = useState(false);

    const handleCloseRemoveModal = () => {
        setRemoveModalOpen(false);
    };

    const handleOnClick = () => {
        onClickHandler();
        setRemoveModalOpen(false);
    }

    const handleOpenRemoveModal = () => {
        setRemoveModalOpen(true);
    };

    return (
        <>
            <Button
                ref={ref}
                onClick={handleOpenRemoveModal}
                className={clsx(!skipNativeClass ? classes.deleteButton : {}, className)}
                {...rest}
            >
                {children}
            </Button>
            <Dialog
                onClose={handleCloseRemoveModal}
                className={classes.removeModal}
                open={removeModalOpen}
            >
                <Box>
                    <img
                        src={CloseIcon}
                        alt=":( Not Found"
                        style={{ cursor: "pointer" }}
                        onClick={handleCloseRemoveModal}
                    />
                </Box>
                <Typography>{hint}</Typography>
                <Box>
                    <Button className={classes.noBtn} onClick={handleCloseRemoveModal}>
                        Nie
                    </Button>
                    <Button className={classes.yesBtn} onClick={() => handleOnClick()}>
                        Tak
                    </Button>
                </Box>
            </Dialog>
        </>
    );
});

export default DeleteButton;
