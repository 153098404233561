import React from "react";
import {Box, makeStyles, createTheme, ThemeProvider} from "@material-ui/core";
import Sidebar from "./Sidebar";
import MobileNavbar from "components/navigation/MobileNavbar";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1560,
        }
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#F7F8FB",
        minHeight: "100vh",
    },
    sidebar: {
        display: "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: 300,
        minHeight: "100vh",
        height: "100%",
        background: "#FFF",
        zIndex: 9,
        borderRight: "1px solid #DDE3EE",
        padding: "32px 16px 24px",

        [theme.breakpoints.up("lg")]: {
            width: 300,
            display: "block",
        },
    },
    content: {
        paddingLeft: 0,
        paddingTop: 0,
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 300,
        },
    },
}));

const Admin = ({children}) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.root}>
                <Box>
                    <Box className={classes.sidebar}>
                        <Sidebar/>
                    </Box>
                    <Box className={classes.content}>{children}</Box>
                </Box>
                <MobileNavbar/>
            </Box>
        </ThemeProvider>
    );
};

export default Admin;
