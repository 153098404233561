import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import Routes from "router/Routes";
import Storage from "utils/Storage";
import {ReactComponent as BookmarkActiveImage} from "assets/images/icons/bookmark_active.svg";
import {ReactComponent as BookmarkNormalImage} from "assets/images/icons/bookmark_normal.svg";
import {ReactComponent as LogoutIcon} from "assets/images/icons/logout.svg";
import {ReactComponent as KeyIcon} from "assets/images/icons/key.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        position: "relative",
        padding: 0,
        "& a": {
            textDecoration: "none",
        },
        "& .MuiListItem-root": {
            height: 55,
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 3),
            "& .MuiListItemIcon-root": {
                minWidth: "auto",
                marginRight: theme.spacing(2),
            },
            "& .MuiTypography-root": {
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 14,
                color: "#454D5F",
            },
        },
    },
    active: {
        background: "#E5F2FF",
        "& .MuiTypography-root": {
            color: "#0066CC!important",
        },
    },
    layoutButton: {
        width: "auto",
        position: "absolute",
        bottom: 10,
        left: 16,
        right: 16,
        border: "1px solid #9F302D",
        "& .MuiTypography-root": {
            color: "#9F302D!important",
        },
    },
    passwordButton: {
        width: "auto",
        position: "absolute",
        bottom: 80,
        left: 16,
        right: 16,
        border: "1px solid #F1F3F8",
        background: "#F1F3F8",
        "& .MuiTypography-root": {
            color: "#003366!important",
        },
    },
    disabled: {
        pointerEvents: "none",
        opacity: 0.7
    }
}));

const Sidebar = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const navLinks = [
        {
            name: t("layout.patientList"),
            link: Routes.Dashboard.PatientList,
        },
        {
            name: t("layout.createPatient"),
            link: Routes.Dashboard.CreatePatient,
        },
        {
            name: t("layout.calendar"),
            link: Routes.Dashboard.Calendar,
        },
        {
            name: t("layout.patientCard"),
            link: "/patient/card",
        },
        {
            name: t("layout.document"),
            link: Routes.Dashboard.Documents,
        },
    ];

    const handleLogout = () => {
        Storage.remove("token");
        Storage.remove("user");
        history.push(Routes.Auth.Login);
    };

    const handleChangePassword = () => {
        history.push(Routes.Dashboard.ChangePassword);
    };

    return (
        <List component="nav" className={classes.root}>
            {navLinks.map((link, index) => {
                const isMatch = location.pathname.indexOf(link.link) > -1;

                return (
                    <Link key={index} to={link.link} className={JSON.parse(Storage.get("user"))?.changed_password_at === null ? classes.disabled : ''}>
                        <ListItem button className={isMatch ? classes.active : ``}>
                            <ListItemIcon>
                                {isMatch ? <BookmarkActiveImage/> : <BookmarkNormalImage/>}
                            </ListItemIcon>
                            <ListItemText>{link.name}</ListItemText>
                        </ListItem>
                    </Link>
                );
            })}
            <ListItem button onClick={handleChangePassword} className={classes.passwordButton}>
                <ListItemIcon>
                    <KeyIcon/>
                </ListItemIcon>
                <ListItemText>{t("layout.changePassword")}</ListItemText>
            </ListItem>
            <ListItem button onClick={handleLogout} className={classes.layoutButton}>
                <ListItemIcon>
                    <LogoutIcon/>
                </ListItemIcon>
                <ListItemText>{t("layout.logout")}</ListItemText>
            </ListItem>
        </List>
    );
};

export default Sidebar;
