import axios from "axios";
import Storage from "utils/Storage";
import Routes from "router/Routes";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URI,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.request.use(
  (config) => {
    if (config.url !== '/change_password' && JSON.parse(Storage.get("user"))?.changed_password_at === null) {
      window.location.href = Routes.Dashboard.ChangePassword;
    }
    const token = Storage.get("token");
    if (token && token != "undefined" && token !== undefined) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      if (error.response?.data.error === "Expired Token") {
        API.auth.refreshToken();
      } else {
        window.location.href = Routes.Auth.Login;
      }
    }
    return Promise.reject(error);
  }
);

API.auth = {
  login: (data) => {
    const uninterceptedAxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT_URI,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return uninterceptedAxiosInstance.post(`/auth/login/doctor`, data);
  },
};

API.patient = {
  create: (data) => API.post(`/patients`, data),
  getList: (data) => API.get(`/patients`, { params: data }),
  delete: (data) => API.delete(`/patients/${data}`),
  update: (id, data) => API.put(`/patients/${id}`, data),
  resetPassword: (data) => API.get(`patients/print_password/${data}`),
  validatePeselCode: (data) =>
    API.get(`patients/validate_pesel`, { params: data }),
};

API.card = {
  getList: (data) => API.get(`/patients/cards`, { params: data }),
  getPatient: (data) => API.get(`/patients/${data}`),
  getPatientBasic: (data) => API.get(`/patients/${data}/basic`),
  getPatientProbes: (data) => API.get(`/patients/${data}/probes`),
  getPatientVisits: (data) => API.get(`/patients/${data}/visits`),
  file: {
    delete: (data) => API.delete(`/files/${data}`),
    download: (data) => API.get(`/files/${data}`),
  },
  note: {
    save: (data) => API.post(`/patients/notes`, data),
    update : (id, data) => API.put(`/patients/notes/${id}`, data),
    delete: (data) => API.delete(`/patients/notes/${data}`),
  },
  visit: {
    getDataBySelectedDate: (data) => API.get(`/visits?date=${data}`),
    getVisitCount: (data) =>
      API.get(`/visits/count?year=${data.year}&month=${data.month}`),
    create: (data) => API.post(`/visits`, data),
    delete: (data) => API.delete(`/visits/${data}`),
    update: (id, data) => API.put(`/visits/${id}`, data),
    show: id => API.get(`/visits/${id}`),
    disconnectSet : id => API.put(`/visits/${id}/disconnectSet`),
    connectSet : (id, setId) => API.put(`/visits/${id}/connectSet/${setId}`),
    connectEmptySet : (id) => API.put(`/visits/${id}/connectEmptySet`),
    updateResults : (id, data) => API.put(`/visits/${id}/updateResults`, data)
  },
  probe: {
    getCarriers: () => API.get(`/probes/carriers`),
    generateCode: (data) => API.post(`/probes/generate_code/${data.patient_id}`, data),
    create: (data) => API.post(`/probes`, data),
    delete: id => API.delete(`/probes/${id}`),
    show: id => API.get(`/probes/${id}`),

  },
  sets: {
    show: id => API.get(`/patients/sets/${id}`),
    store: data => API.post('patients/sets', data),
    getList: id => API.get(`patients/${id}/sets`)
  }
};

API.settings = {
  documents: () => API.get(`/settings/documents/doctor`),
};

API.inclusionCriteriaGroup = {
  getList: () => API.get(`/inclusion_criteria_group`),
};

API.exclusionCriteriaGroup = {
  getList: () => API.get(`/exclusion_criteria`),
};

API.medicine = {
  getList: () => API.get(`/medicine`),
};

API.unit = {
  getList: () => API.get(`/units/all`),
};

API.doctor = {
  getList: (data) => API.get(`/doctors/all`, data && { params: { unit_id: data } }),
};

API.message = {
  getList: params => API.get(`/notifications`, { params }),
  sendMessage: data => API.post(`/notifications`, data),
  editMessage: (id, data) => API.put(`/notifications/${id}`, data),
  deleteMessage: (id) => API.delete(`/notifications/${id}`),
};
API.changePassword = (data) => API.put('/change_password', data);
export default API;
