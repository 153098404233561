import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  LinearProgress,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CalendarImage from "assets/images/pages/patient/calendar.png";
import TrashIcon from "assets/images/icons/trash.svg";
import EditIcon from "assets/images/icons/edit.svg";
import CheckIcon from "assets/images/icons/check-circle-default.svg";
import CloseIcon from "assets/images/icons/close.svg";
import Constants from "utils/Constants";
import API from "apis/API";
import DeleteButton from "components/buttons/DeleteButton";
import * as Actions from "store/actions/CardAction";
import CreateAppointmentModal from "components/modals/CreateAppointmentModal";
import SortableIcon from "assets/images/icons/sort.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
      color: "#454D5F",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      marginBottom: 25,
    },
  },
  tab: {
    width: "100%",
    display: "inline-flex",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    [theme.breakpoints.up("lg")]: {
      width: "auto",
    },
  },
  tabButton: {
    width: "50%",
    height: 56,
    borderRadius: 8,
    fontFamily: "Lato",
    fontSize: 16,
    color: "#6C7689",
    textTransform: "none",
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      padding: "0 32px",
    },
  },
  selectedTabButton: {
    background: "#E5F2FF",
    fontWeight: 700,
    color: "#0066CC",
  },
  card: {
    position: "relative",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: "8px 24px",
    marginBottom: 16,
    boxShadow: "none",
    background: "#F7F8FB",
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
      padding: "22px 32px",
    },
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& > img": {
        width: 32,
      },
      "& > .MuiBox-root": {
        display: "flex",
        flexDirection: "column-reverse",
        marginLeft: 24,
        "& > .MuiBox-root": {
          flexDirection: "column-reverse",
          display: "flex",
          alignItems: "flex-start",
          marginBottom: 8,
        },
        "& .MuiTypography-root": {
          fontFamily: "Lato",
          fontWeight: 700,
        },
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      "& > .MuiBox-root": {
        "& > img": {
          width: "51px!important",
        },
        "& > .MuiBox-root": {
          marginLeft: "32px!important",
          flexDirection: "column!important",
          "& > .MuiBox-root": {
            flexDirection: "row!important",
            alignItems: "center!important",
          },
        },
      },
    },
  },
  paddingBottom: {
    paddingBottom: 60,
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 32,
    },
  },
  cardBody: {
    paddingTop: 20,
    display: "none",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontSize: 16,
      color: "#003366",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  historyTitle: {
    fontSize: 20,
    display: "none",
    fontFamily: "Lato",
    color: "#454D5F",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      display: "flex",
    },
  },
  name: {
    color: "#003366",
    fontSize: 16,
    marginRight: 21,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
  time: {
    color: "#003366",
  },
  doctor: {
    color: "#003366",
    fontSize: 20,
    [theme.breakpoints.up("lg")]: {
      color: "#454D5F",
      fontSize: 16,
    },
  },
  label: {
    fontWeight: 700,
    marginBottom: 14,
  },
  iconButton: {
    minWidth: 41,
    width: 41,
    height: 41,
    background: "#F7F8FB",
    border: "1.70833px solid #DDE3EE",
    borderRadius: 8,
    marginLeft: 8,
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "flex",
    },
  },
  detailButton: {
    textTransform: "none",
    minHeight: 48,
    padding: "0 12px",
    borderRadius: 0,
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#FFF",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    background: "#0066CC",
    [theme.breakpoints.up("lg")]: {
      border: "2px solid #003366",
      padding: "0 36px",
      width: "auto",
      position: "relative",
      color: "#003366",
      background: "#FFF",
      borderRadius: 8,
      minHeight: 56,
    },
  },
  fillButton: {
    width: "100%",
    display: "inline-block",
  },

  removeModal: {
    "& .MuiPaper-root": {
      width: 600,
      padding: theme.spacing(3, 4, 4),
      "& .MuiBox-root": {
        "&:first-child": {
          display: "flex",
          justifyContent: "flex-end",
        },
        "&:last-child": {
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      "& .MuiTypography-root": {
        padding: theme.spacing(5, 0),
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#454D5F",
      },
    },
  },
  yesBtn: {
    width: 140,
    height: 56,
    background: "#D26360",
    borderRadius: 8,
    color: "#FFF",
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    marginLeft: theme.spacing(3),
    "&:hover": {
      background: "#D26360",
    },
  },
  noBtn: {
    width: 140,
    height: 56,
    background: "#FFF",
    border: "1px solid #003366",
    borderRadius: 8,
    color: "#003366",
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
  },
  marginLinear: {
    marginTop: 20,
  }
}));

const Visits = ({ onShowDetail }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { visits } = useSelector((state) => state.card.selectedPatient);
  const { selectedSubTab, selectedPatientID, isTabLoading } = useSelector((state) => state.card);
  const tabs = [
    { id: "future", name: t("pages.patient.visits.future") },
    { id: "past", name: t("pages.patient.visits.last") },
  ];
  const [selectedTab, setSelectedTab] = useState(
    selectedSubTab?.childTab || tabs[0].id
  );
  let savedSelectedTab = useRef(null);
  const [visitList, setVisitList] = useState(visits);
  const [open, setOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [selectedVisitID, setSelectedVisitID] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [sort, setSort] = useState(false);

  useEffect(() => {
    dispatch(Actions.getSelectedPatientVisits(selectedPatientID));
  }, []);

  useEffect(() => {
    setVisitList(visits);
  }, [visits]);

  const handleChangeTab = (tabID) => {
    setSelectedTab(tabID);
    savedSelectedTab.current = tabID;
    dispatch(Actions.setSubTab({ ...selectedSubTab, childTab: tabID }));
  };

  const handleCloseRemoveModal = () => setRemoveModalOpen(false);

  const handleRemove = () => {
    setIsDeleting(true);
    API.card.visit.delete(selectedVisitID).then((res) => {
      setIsDeleting(false);
      if (res.data.status === "success") {
        dispatch(Actions.deleteVisit(selectedVisitID));
        handleCloseRemoveModal();
      }
    });
  };

  const handleEdit = (data) => {
    dispatch(Actions.selectVisit(data));
    setOpen(true);
  };

  const handleMarkAsPast = (data) => {
    API.card.visit.update(data.id, { status: "past" }).then((res) => {
      if (res.data.status === "success") {
        dispatch(Actions.updateVisits(res.data.visit));

        dispatch(Actions.selectVisit(res.data.visit)).then(() => {
          onShowDetail();
        });
      }
    });
  };

  const handleClose = () => {
    dispatch(Actions.deleteSelectedVisit());
    setOpen(false);
  };

  const handleOpenRemoveModal = (id) => {
    API.card.visit.delete(id).then((res) => {
      if (res.data.status === "success") {
        dispatch(Actions.deleteVisit(id));
      }
    });
  };

  const handleShowDetail = (visit) => () => {
    dispatch(Actions.selectVisit(visit)).then(() => {
      onShowDetail();
    });
  };

  const handleSort = () => {
    setSort(!sort);
  }

  return (
    <>
      {isTabLoading && <LinearProgress className={classes.marginLinear} />}
      {!isTabLoading && <>
          <Box>
            <Typography className={classes.title}>
              {t("pages.patient.visits.title")}
            </Typography>
            <Box className={classes.toolBar}>
              <Box className={classes.tab}>
                {tabs.map((tab) => (
                  <Button
                    key={tab.id}
                    className={`${classes.tabButton} ${
                      tab.id === selectedTab ? classes.selectedTabButton : ``
                    }`}
                    onClick={() => handleChangeTab(tab.id)}
                  >
                    {tab.name}
                  </Button>
                ))}
              </Box>
              <Button onClick={handleSort}>
                SORTOWANIE: DATA WIZYTY
                <img
                  src={SortableIcon}
                  alt=":( Not Found"
                />
              </Button>
            </Box>
            <Box>
              {visitList
                ?.filter(
                  (visit) =>
                    (selectedTab == "past" && visit.status == "past") ||
                    (selectedTab == "future" && visit.status == "upcoming")
                )
                .sort((a, b) => {
                  if (sort) {
                    return (
                      new Date(b.date + " " + b.started_at) -
                      new Date(a.date + " " + a.started_at)
                    );
                  } else {
                    return (
                      new Date(a.date + " " + a.started_at) -
                      new Date(b.date + " " + b.started_at)
                    );
                  }
                })
                ?.map((row, index) => {
                  return (
                    <Card
                      className={`${classes.card} ${
                        selectedTab === "past" ? classes.paddingBottom : ``
                      }`}
                      key={index}
                    >
                      <Box className={classes.cardHeader}>
                        <Box>
                          <img src={CalendarImage} alt=":( Not Found" />
                          <Box>
                            <Typography className={classes.historyTitle}>
                              { sort === true ? Constants.order[visitList
                                .filter(
                                  (visit) =>
                                    (selectedTab == "past" && visit.status == "past") ||
                                    (selectedTab == "future" && visit.status == "upcoming")
                                ).length - index - 1] : Constants.order[index] }
                            </Typography>
                            <Box>
                              <Typography className={classes.name}>
                                {Constants.days[moment(row.date).format("dddd")]},{" "}
                                {moment(row.date).format("DD.MM.YYYY")}
                              </Typography>
                              <Typography className={classes.time}>
                                {row.started_at.slice(0, -3)} -{" "}
                                {row.ended_at.slice(0, -3)}
                              </Typography>
                            </Box>
                            <Typography className={classes.doctor}>
                              {row.doctor.firstname} {row.doctor.surname}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          {selectedTab === "past" ? (
                            <Button
                              className={classes.detailButton}
                              onClick={handleShowDetail(row)}
                            >
                              {t("pages.patient.visits.see")}
                            </Button>
                          ) : (
                            <>
                              <DeleteButton
                                className={`${classes.iconButton} ${classes.deleteButton}`}
                                skipNativeClass={true}
                                hint={"Czy na pewno chcesz anulować tę wizytę?"}
                                onClickHandler={() => handleOpenRemoveModal(row.id)}
                              >
                                <img
                                  src={TrashIcon}
                                  alt="Usuń"
                                  title="Odwołaj wizytę"
                                />
                              </DeleteButton>
                              <Button
                                onClick={() => handleEdit(row)}
                                className={`${classes.iconButton} ${classes.editButton}`}
                              >
                                <img
                                  src={EditIcon}
                                  alt="Zmień termin"
                                  title="Zmień termin wizyty"
                                />
                              </Button>

                              <Button
                                className={`${classes.iconButton} ${classes.editButton}`}
                                onClick={() => handleMarkAsPast(row)}
                              >
                                <img src={CheckIcon} title="Oznacz jako odbytą" />
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                      {row.isDone && (
                        <Box className={classes.cardBody}>
                          <Typography className={classes.label}>
                            Komentarz lekarza:
                          </Typography>
                          <Typography>{row.comment}</Typography>
                        </Box>
                      )}
                    </Card>
                  );
                })}
            </Box>
          </Box>
          <Dialog
            onClose={handleCloseRemoveModal}
            className={classes.removeModal}
            open={removeModalOpen}
          >
            <Box>
              <img
                src={CloseIcon}
                alt=":( Not Found"
                style={{ cursor: "pointer" }}
                onClick={handleCloseRemoveModal}
              />
            </Box>
            <Typography>Czy na pewno chcesz anulować tę wizytę?</Typography>
            <Box>
              <Button className={classes.noBtn} onClick={handleCloseRemoveModal}>
                Nie
              </Button>
              <Button className={classes.yesBtn} onClick={() => handleRemove()}>
                {isDeleting ? (
                  <CircularProgress size={30} style={{ color: "#FFF" }} />
                ) : (
                  <>Tak</>
                )}
              </Button>
            </Box>
          </Dialog>
          <CreateAppointmentModal open={open} handleClose={handleClose} />
        </>
      }
    </>
  );
};

export default Visits;
