import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Routes from "router/Routes";
import * as Actions from "store/actions/CardAction";
import BadgeActiveIcon from "assets/images/icons/mobile/badge_active.svg";
import BadgeNormalIcon from "assets/images/icons/mobile/badge_normal.svg";
import ListActiveIcon from "assets/images/icons/mobile/list_active.svg";
import ListNormalIcon from "assets/images/icons/mobile/list_normal.svg";
import AccountActiveIcon from "assets/images/icons/mobile/account_active.svg";
import AccountNormalIcon from "assets/images/icons/mobile/account_normal.svg";
import BellActiveIcon from "assets/images/icons/mobile/bell_active.svg";
import BellNormalIcon from "assets/images/icons/mobile/bell_normal.svg";
import DocumentActiveIcon from "assets/images/icons/mobile/document_active.svg";
import DocumentNormalIcon from "assets/images/icons/mobile/document_normal.svg";
import Storage from "utils/Storage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 76,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    background: "#F7F8FB",
    border: "1px solid #DDE3EE",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  item: {
    minWidth: 52,
    position: "relative",
    "& > .MuiBox-root": {
      textTransform: "none",
      "& > .MuiBox-root": {
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: 10,
          fontWeight: 700,
          color: "#003366",
          marginTop: 7,
        },
        "& img": {
          width: 24,
          height: 24,
          objectFit: "contain",
        },
      },
    },
  },
}));

const MobileNavbar = () => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = [
    {
      id: "card",
      name: t("layout.shortPatientCard"),
      link: Routes.Dashboard.PatientCard,
      activeImage: BadgeActiveIcon,
      normalImage: BadgeNormalIcon,
    },
    {
      id: "documents",
      name: t("layout.document"),
      link: Routes.Dashboard.Documents,
      activeImage: DocumentActiveIcon,
      normalImage: DocumentNormalIcon,
    },
    {
      id: "patientList",
      name: t("layout.shortPatientList"),
      link: Routes.Dashboard.PatientList,
      activeImage: ListActiveIcon,
      normalImage: ListNormalIcon,
    },
    {
      id: "calendar",
      name: t("layout.calendar"),
      link: Routes.Dashboard.Calendar,
      activeImage: BellActiveIcon,
      normalImage: BellNormalIcon,
    },
    {
      id: "account",
      name: t("layout.account"),
      link: Routes.Dashboard.Account,
      activeImage: AccountActiveIcon,
      normalImage: AccountNormalIcon,
    },
  ];

  const handleRedirect = (route) => {
    history.push(route.link);
    if (route.id === "card") {
      dispatch(Actions.setSelectedPatientID(null));
      dispatch(Actions.deleteSelectedTab());
    }
  };

  return (
    <Box className={`${classes.root} ${JSON.parse(Storage.get("user"))?.changed_password_at === null ? classes.disabled : ''}`}>
      {routes.map((route) => {
        return (
          <Box key={route.id} className={classes.item}>
            <Box onClick={() => handleRedirect(route)}>
              <Box>
                {route.link === location.pathname ? (
                  <img src={route.activeImage} alt=":( Not Found" />
                ) : (
                  <img src={route.normalImage} alt=":( Not Found" />
                )}
                <Typography
                  style={{
                    color:
                      route.link === location.pathname ? "#0066CC" : "#003366",
                  }}
                >
                  {route.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MobileNavbar;
