import React from 'react';
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	table: {
		border: "1px solid #DDE3EE",
		borderRadius: theme.spacing(1),
		marginBottom: theme.spacing(4),
		"& .MuiTableHead-root": {
			background: "#E7ECF5",
		},
		"& .MuiTableCell-body": {
			fontFamily: "Lato",
			fontSize: 20,
			lineHeight: 1.4,
			color: "#003366"
		}
	},
}));

const ProbesTable = ({ probes, renderRow, downloadLabel }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<TableContainer className={classes.table}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>{t('pages.patient.sets.probeCode')}</TableCell>
						<TableCell>{t('pages.patient.sets.materialType')}</TableCell>
						<TableCell>{t('pages.patient.sets.status')}</TableCell>
						<TableCell>{downloadLabel && t('pages.patient.sets.amount')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{probes.map(renderRow)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ProbesTable;