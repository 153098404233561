// Substring pesel parts
const peselSubs = (code, start = 0, end = 2) => parseInt(code.substring(start, end), 10);
// Checking if pesel valid
const isValidPesel = code => {
    if(typeof code !== 'string')
        return false;

    let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    let sum = 0;
    let controlNumber = parseInt(code.substring(10, 11));

    for (let i = 0; i < weight.length; i++) {
        sum += (parseInt(code.substring(i, i + 1)) * weight[i]);
    }

    sum = sum % 10;

    return (10 - sum) % 10 === controlNumber;
};
// Get gender from pesel code
const getSex = code => {
    let sex;

    if (parseInt(code.substring(9, 10), 10) % 2 === 1) {
        sex = "m";
    } else {
        sex = "k";
    }

    return sex;
};
// Decoding pesel and return humanize data like sex, birthdate
export const peselDecoder = code => {
    let valid = isValidPesel(code);

    if (!!valid) {
        let year = peselSubs(code);
        let month = peselSubs(code, 2, 4) - 1;
        let day = peselSubs(code, 4, 6);
        let sex = getSex(code);

        if (month > 80) {
            year = year + 1800;
            month = month - 80;
        } else if (month > 60) {
            year = year + 2200;
            month = month - 60;
        } else if (month > 40) {
            year = year + 2100;
            month = month - 40;
        } else if (month > 20) {
            year = year + 2000;
            month = month - 20;
        } else {
            year += 1900;
        }

        return {
            valid: !!valid,
            sex,
            date: new Date().setFullYear(year, month, day)
        }
    }

    console.warn('Pesel not valid');
    return {
        valid: false,
    };
};
