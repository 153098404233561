import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import API from "apis/API";
import * as Actions from "store/actions/CardAction";
import Validator, { Required, validate } from "utils/Validator";
import CloseIcon from "assets/images/icons/close.svg";
import { useTranslation } from "react-i18next";
import TextInput from "components/form/TextInput";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      width: 477,
    },
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(1),
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  dialogBody: {
    padding: "20px 32px 0",
  },
  subtitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
    color: "#454D5F",
  },
  button: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 3),
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
  },
  cancelButton: {
    border: "1px solid #A6AEBF",
    color: "#003366",
    marginRight: theme.spacing(2),
  },
  saveButton: {
    background: "#003366",
    color: "#FFFFFF",
    "&:hover": {
      background: "#003366",
    }
  },
}));

const SubTitle = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.subtitle} {...rest}>
      {children}
    </Typography>
  );
};

const CreateModal = ({ open, onClose, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedPatientID, list } = useSelector((state) => state.card);
  const purposes = [
    { value: 'home', label: 'Do samodzielnego pobrania w domu' },
    { value: 'clinic', label: 'Do wykorzystania na wizycie' },
  ];
  const defaultData = {
    patient_id: selectedPatientID,
    street: '',
    building: '',
    postcode: '',
    city: '',
    purpose: 'home',
  };

  const [selectedPatient, setSelectedPatient] = useState(
    list.data.find(item => item.id === selectedPatientID)
  );
  const [formData, setFormData] = useState(defaultData);
  const [errors, setErrors] = useState(null);
  const Validators = {
    patient_id: new Validator(Required),
    /*street: new Validator(Required),
    building: new Validator(Required),
    postcode: new Validator(Required),
    city: new Validator(Required),*/
    purpose: new Validator(Required),
  };

  useEffect(() => {
    setFormData(defaultData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setFormData(prev => ({ ...prev, patient_id: selectedPatient?.id }));
  }, [selectedPatient]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    let _errors = validate(formData, Validators);
    setErrors(_errors);
    if (_errors) return;
    API.card.sets.store(formData).then(res => {
      dispatch(Actions.addSet(res.data.patient_sets));
      onClose();
    });
  };

  return (
    <Dialog onClose={onClose} className={classes.dialog} open={open}>
      <Box className={classes.dialogHeader}>
        <Typography>{t('pages.patient.sets.add')}</Typography>
        <Button onClick={onClose} className={classes.closeButton}>
          <img src={CloseIcon} alt="Close" />
        </Button>
      </Box>
      <Box className={classes.dialogBody}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SubTitle>
              {t('pages.patient.sets.patient')}
            </SubTitle>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={list.data}
              value={selectedPatient}
              onChange={(event, newValue) => setSelectedPatient(newValue)}
              disableClearable
              getOptionLabel={option => `${option.firstname} ${option.surname}`}
              renderOption={option =>
                <Box>
                  {option.firstname} {option.surname}
                  <Typography color="textSecondary">PESEL: {option.pesel}</Typography>
                </Box>
              }
              renderInput={params => <TextField {...params} variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextInput
                  label="PESEL"
                  value={selectedPatient?.pesel || ''}
                  gutterBottom={false}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  label={t('pages.patient.sets.patientNumber')}
                  value={selectedPatient?.id || ''}
                  gutterBottom={false}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <div style={{display: 'none'}}>
            <Grid item xs={12}>
              <SubTitle>
                {t('pages.patient.personal.address')}
              </SubTitle>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t('pages.patient.sets.street')}
                name="street"
                value={formData.street}
                onChange={handleChange}
                error={!!errors?.street}
                required
                gutterBottom={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <TextInput
                    label={t('pages.patient.sets.building')}
                    name="building"
                    value={formData.building}
                    onChange={handleChange}
                    error={!!errors?.building}
                    required
                    gutterBottom={false}
                  />
                </Grid>
                <Grid item xs>
                  <TextInput
                    label={t('pages.patient.sets.postcode')}
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                    error={!!errors?.postcode}
                    required
                    gutterBottom={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t('pages.patient.sets.city')}
                name="city"
                value={formData.city}
                onChange={handleChange}
                error={!!errors?.city}
                required
                gutterBottom={false}
              />
            </Grid>
          </div>
          <Grid item xs={12}>
            <SubTitle>
              {t('pages.patient.sets.setPurpose')}
            </SubTitle>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 14, color: '#6C7689', marginBottom: 8 }}>
              {t('pages.patient.sets.purpose')}
            </Typography>
            <Select
              value={formData.purpose}
              fullWidth
              name="purpose"
              onChange={handleChange}
              variant="outlined"
              error={!!errors?.purpose}
            >
              {purposes.map((purpose, i) => {
                return (
                  <MenuItem key={i} value={purpose.value}>
                    {purpose.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" p={4}>
        <Button onClick={onClose} className={clsx(classes.button, classes.cancelButton)}>
          {t('common.cancel')}
        </Button>
        <Button onClick={handleSave} className={clsx(classes.button, classes.saveButton)}>
          {t('pages.patient.sets.add')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CreateModal;
