import React, {useEffect, useState} from "react";
import {
    Box,
    InputAdornment,
    LinearProgress,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "store/actions/CardAction";
import SearchIcon from "assets/images/icons/search.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        zIndex: 12,
        top: 0,
        left: 300,
        width: 423,
        height: "100vh",
        flexShrink: 0,
        background: "#FFF",
        flexDirection: "column",

        [theme.breakpoints.down("md")]: {
            width: 300,
            display: "block",
        },

        [theme.breakpoints.down("md")]: {
            width: "100%",
            left: 0,
            // display: "none",
        },
    },
    header: {
        padding: theme.spacing(4, 3, 3, 3),
        borderBottom: "1px solid #DDE3EE",
        "& > .MuiTypography-root": {
            fontSize: 20,
            fontWeight: 700,
            color: "#003366",
            marginBottom: theme.spacing(3),
        },
    },
    searchField: {
        width: "100%",
    },
    body: {
        height: "100%",
        padding: theme.spacing(2),
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
            paddingBottom: theme.spacing(10),
        },
    },
    activeItem: {
        background: "#E6F2FF!important",
        "& .MuiTypography-root": {
            color: "#0066CC!important",
        },
    },
    item: {
        borderRadius: 16,
        background: "#FFF",
        marginBottom: theme.spacing(1),
        padding: "20px 40px",
        transition: "0.3s",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        "& > .MuiTypography-root": {
            fontSize: 16,
            fontWeight: 700,
            color: "#003366",
            marginRight: 55,
        },
        "& .MuiBox-root": {
            "& > .MuiTypography-root": {
                "&:first-child": {
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#003366",
                    marginBottom: theme.spacing(1),
                },
                "&:last-child": {
                    fontSize: 14,
                    color: "#6C7689",
                },
            },
        },
        "&:hover": {
            background: "#E6F2FF",
            "& .MuiTypography-root": {
                color: "#0066CC!important",
            },
        },
    },
}));

const Sidebar = ({showSidebar, onClick}) => {
    const classes = useStyles();
    const {selectedPatientID} = useSelector((state) => state.card);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        search: "",
        limit: 20,
        offset: 0,
    });

    const {patientList, isLoading} = useSelector((state) => {
        return {patientList: state.card.list, isLoading: state.card.isLoading};
    });

    const handleChangeSearch = (e) => {
        setFormData({...formData, search: e.target.value});
    };

    useEffect(() => {
        dispatch(Actions.getPatientList(formData));
    }, [formData]);

    const handleSelectPatient = (id) => {
        onClick();
        dispatch(Actions.setSelectedPatientID(id));
        dispatch(Actions.deleteSelectedTab());
    };

    return (
        <Box className={classes.root} display={showSidebar ? 'flex' : 'none'}>
            <Box className={classes.header}>
                <Typography>Wybierz pacjenta, aby wyświetlić jego kartę</Typography>
                <TextField
                    className={classes.searchField}
                    value={formData.search}
                    placeholder="Wyszukaj pacjenta"
                    onChange={handleChangeSearch}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt=":( Not Found"/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {isLoading && <LinearProgress/>}
            <Box className={classes.body}>
                {!isLoading &&
                    patientList.data?.map((item, index) => {
                        return (
                            <Box
                                key={item.id}
                                className={`${classes.item} ${item.id === selectedPatientID ? classes.activeItem : ``
                                }`}
                                onClick={() => handleSelectPatient(item.id)}
                            >
                                <Typography>{item.id}</Typography>
                                <Box>
                                    <Typography>
                                        {item.surname} {item.firstname}
                                    </Typography>
                                    <Typography>PESEL: {item.pesel}</Typography>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default Sidebar;
