const Constants = {
  Locales: ["pl", "en"],
  DefaultStringLimit: 255,
  EmailPattern: new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  ),
  LatitudePattern: new RegExp(/^((-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/),
  LongitudePattern: new RegExp(/^((-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/),
  FullHexCodePattern: new RegExp(/^#[0-9A-F]{6}$/i),
  days: {
    Monday: "Poniedziałek",
    Tuesday: "Wtorek",
    Wednesday: "Środa",
    Thursday: "Czwartek",
    Friday: "Piątek",
    Saturday: "Sobota",
    Sunday: "Niedziela",
  },
  order: [
    "Pierwsza wizyta",
    "Druga wizyta",
    "Trzecia wizyta",
    "Czwarta wizyta",
    "Piąta wizyta",
    "Szósta wizyta",
    "Siódma wizyta",
    "Ósma wizyta",
    "Dziewiąta wizyta",
    "Dziesiąta wizyta",
  ],
  formulasMap: [
    { pl_name: 'Sotalol', en_name: 'Sotalol', cas: '3930-20-9' },
    { pl_name: 'Metoprolol', en_name: 'Metoprolol tartate', cas: '56392-17-7'},
    { pl_name: 'Bisoprolol', en_name: 'Bisoprolol hemifumarate salt', cas: '104344-23-2' },
    { pl_name: 'Propafenon', en_name: 'Propafenone hydrochloride', cas: '34183-22-7' },
    { pl_name: 'OH-propafenon', en_name: '5-Hydroxypropafenone hydrochloride', cas: '86383-32-6' }, // @TODO can't find matching in the backend
    { pl_name: 'Karwedilol', en_name: 'Carvedilol', cas: '72956-09-3' },
    { pl_name: 'Nebiwolol', en_name: 'Nebivolol hydrochloride', cas: '152520-56-4' },
    { pl_name: 'Dronedaron', en_name: 'Dronedarone hydrochloride', cas: '141625-93-6' },  // @TODO can't find matching in the backend
    { pl_name: 'D-amiodaron', en_name: 'Desethylamiodarone Hydrochloride', cas: '96027-74-6' },  // @TODO can't find matching in the backend
    { pl_name: 'Amiodaron', en_name: 'Amiodarone hydrochloride', cas: '19774-82-4' },
    { pl_name: 'Digoksyna', en_name: 'Digoxin', cas: '20830-75-5' },
    { pl_name: 'Perindopryl', en_name: 'Perindopril erbumine', cas: '107133-36-8' },
    { pl_name: 'Enalapryl', en_name: 'Enalapril maleate salt', cas: '76095-16-4' },
    { pl_name: 'Ramipryl', en_name: 'Ramipril', cas: '87333-19-5' },
    { pl_name: 'Eplerenon', en_name: 'Eplerenone', cas: '107724-20-9' },
    { pl_name: 'Spironolakton', en_name: 'Spironolactone', cas: '52-01-7' },
    { pl_name: 'Zofenopril', en_name: 'Zofenopril calcium salt', cas: '81938-43-4' },
  ],
  getFormulaLink: medicineName => {
    (medicineName === 'Polfenon,Rytmonorm,Tonicard' || medicineName === 'Polfenon, Rytmonorm, Tonicard') && (medicineName = 'propafenon');
    (medicineName === 'Opacorden,Cordarone' || medicineName === 'Opacorden, Cordarone') && (medicineName = 'amiodaron');
    (medicineName === 'Biosotal,SotaHEXAL,Sotalol Aurovitas' || medicineName === 'Biosotal, SotaHEXAL, Sotalol Aurovitas') && (medicineName = 'sotalol');
    Constants.formulasMap.sort(function(a, b){
      return b.pl_name.length - a.pl_name.length;
    });
    let item = Constants.formulasMap.find(formula => {
      let _medicineName = medicineName.toLocaleLowerCase();
      let _formulaPlName = formula.pl_name.toLocaleLowerCase();
      if (_medicineName.indexOf(_formulaPlName) > -1) {
        return true;
      }
    });
    if (item === undefined) {
      Constants.formulasMap.sort(function(a, b){
        return b.en_name.length - a.en_name.length;
      });
      item = Constants.formulasMap.find(formula => {
        let _medicineName = medicineName.toLocaleLowerCase();
        let _formulaEnName = formula.en_name.toLocaleLowerCase();
        if (_medicineName.indexOf(_formulaEnName) > -1) {
          return true;
        }
      });
    }
    
    return `https://pubchem.ncbi.nlm.nih.gov/#query=${item?.cas || medicineName}`;
  }
};

export default Constants;
