import React from "react";
import SetsTab from 'components/patientParts/Sets';

const Sets = () => {
  return (
    <SetsTab />
  );
};

export default Sets;
