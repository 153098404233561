import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	makeStyles,
	TableCell,
	TableRow,
	Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TrashIcon from "assets/images/icons/trash.svg";
import SearchIcon from "assets/images/icons/search.svg";
import PrintIcon from "assets/images/icons/print.svg";

import API from 'apis/API';
import PrimaryButton from 'components/buttons/PrimaryButton';

import FormModal from './CreateModal';
import DownloadLabelModal from './DownloadLabelModal';
import ProbesTable from './ProbesTable';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import DeleteButton from "../../../buttons/DeleteButton";


const useStyles = makeStyles(theme => ({
	downloadLabelButton: {
		padding: theme.spacing(0.8, 2)
	},
	detailButton: {
		marginRight: theme.spacing(1),
		width: 35,
		borderRadius: 8,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "#003366",
		"& img": {
			filter: "brightness(0) invert(1)",
		},
		"&:hover": {
			background: "#003366",
		},
	},
	deleteButton: {
		marginLeft: theme.spacing(1),
		width: 35,
		borderRadius: 8,
		background: "#D26360",
		"&:hover": {
			background: "#D26360",
		},
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& img": {
			filter: "brightness(0) invert(1)",
		},
	},
	printButtons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		flexDirection: "column",

		"& > button ": {
			width: "100%",
			margin: "0 !important",
			marginBottom: "10px !important"
		},

		[theme.breakpoints.up("lg")]: {
			flexDirection: "row",
			alignItems: "center",

			"& > button ": {
				width: "auto",
				marginLeft: "10px !important",
			}
		},
	}
}));

const materialTypes = {
	venous: 'Żylna',
	capillary: "Włośniczkowa"
};

const Probes = ({ defaultProbes, selectedSet, onShowProbe }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [openFormModal, setOpenFormModal] = useState(false);
	const [openDownloadLabelModal, setOpenDownloadLabelModal] = useState(false);
	const [probes, setProbes] = useState([]);

	useEffect(() => {
		if (!defaultProbes) return;

		setProbes(defaultProbes);
	}, [defaultProbes]);

	const set = selectedSet;

	const handleClose = (probe) => {
		if (probe?.id) {
			setProbes(prev => {
				prev.push(probe);
				return [...prev];
			});
		}

		setOpenFormModal(false);
	};

	const handleDelete = (id) => {

		API.card.probe.delete(id).then(res => {
			if (res.data.status === 'success') {
				setProbes(prev => {
					let index = prev.findIndex(probe => probe.id === id);
					prev.splice(index, 1);
					return [...prev];
				});
			}
		});
	};

	const handleShowProbe = probe => () => {
		onShowProbe(probe);
	}

	const handlePrint = probesList => () => {
		if (typeof probesList === 'number') {
			probesList = { [probesList]: 1 };
		} else {
			probesList = {};
			Object.entries(probes).forEach(entry => {
				const [key, value] = entry;
				probesList[value.id] = value.amount;
			});
		}

		let codeToPrint = "\xef\xbb\xbf";

		Object.keys(probesList).forEach((singleProbe, key) => {
			let probe = probes.find(el => el.id == singleProbe);
			let amount = probesList[singleProbe];

			let hintText = '';

			if (probe.collect_place === 'clinic') {
				hintText = 'POR - ';
			} else {
				hintText = 'DOM - ';
			}
			if (probe.type_of_material === 'venous') {
				hintText += 'ŻYL';
			} else {
				hintText += 'WŁO';
			}

			// Check if the printer is ready

			for (let c = 0; c < amount; c++) {
				codeToPrint += `^XA
					^FO375,50,0
					^BXN,4,200
					^FD${probe.code}^FS
					^CF0,35
					^FO320,110,0
					^CI31
					^FD${hintText}^FS
					^CF0,35
					^FO340,150,0
					^FD${probe.code}^FS
					^XZ`;
			}


			/*** CUSTOM PRINT BY LIB ***/
			/*const label = new Label();
			label.printDensity = new PrintDensity(PrintDensityName['8dpmm']);
			label.width = 100;
			label.height = 300;
			label.padding = new Spacing(10);


			const barcode = new Barcode();
			barcode.type = new BarcodeType(BarcodeTypeName.QRCode);
			barcode.top = 20;
			barcode.left = 355;
			barcode.data = probe.code;
			barcode.width = 80;
			barcode.height = 80;


			label.content.push(barcode);


			const text = new Text();

			label.content.push(text);

			if (probe.collect_place == 'clinic')
			{
				text.text = 'POR - ';
			} else {
				text.text = 'DOM - ';
			}

			if (probe.type_of_material == 'venous')
			{
				text.text += 'WLO';
			} else {
				text.text += 'ZYL';
			}

			text.top = 120;
			text.fontFamily = new FontFamily(FontFamilyName.F);
			//text.verticalAlignment = new Alignment(AlignmentValue.Center);
			text.horizontalAlignment = new Alignment(AlignmentValue.Center);
			text.characterWidth = 18;
			text.characterHeight = 37;


			const textCode = new Text();

			label.content.push(textCode);

			textCode.top = 160;
			textCode.text = probe.code;
			textCode.fontFamily = new FontFamily(FontFamilyName.F);
			//textCode.verticalAlignment = new Alignment(AlignmentValue.End);
			textCode.horizontalAlignment = new Alignment(AlignmentValue.Center);
			textCode.characterWidth = 18;
			textCode.characterHeight = 37;

			codeToPrint += label.generateZPL();
			/**** *****/
		});

		console.log(codeToPrint);

		try {
			const browserPrint = new ZebraBrowserPrintWrapper();

			browserPrint.getDefaultPrinter().then((defaultPrinter) => {
				browserPrint.setPrinter(defaultPrinter);

				browserPrint.checkPrinterStatus().then((printerStatus) => {
					if (printerStatus.isReadyToPrint) {
						browserPrint.print(codeToPrint);
					} else {
						alert('Drukarka nie jest dostępna (#304): ' + printerStatus.errors.join(", "));
					}
				}).catch((error) => {
					alert('Drukarka nie jest dostępna (#204): ' + error);
				});
			}).catch((error) => {
				alert('Drukarka nie jest dostępna (#104): ' + error);
			});


		} catch (error) {
			alert('Błąd podczas inicjalizacji wydruku: ' + error);
		}
	};

	const renderRow = (probe, i) => (
		<TableRow key={i}>
			<TableCell>{probe.code}</TableCell>
			<TableCell>{materialTypes[probe.type_of_material]}</TableCell>
			<TableCell>{t('pages.patient.probes.' + probe.status)}{/*moment(probe.created_at).format('DD.MM.YYYY, HH:mm')*/}</TableCell>
			<TableCell>
				<Box display="flex" alignItems="stretch">
					{onShowProbe &&
						<Tooltip
							title="Wyświetl szczegóły próbki"
							aria-label="Wyświetl szczegóły próbki"
							placement="top"
							arrow
						>
							<Button
								className={classes.detailButton}
								onClick={handleShowProbe(probe)}
							>
								<img src={SearchIcon} alt="Wyświetl szczegóły próbki" />
							</Button>
						</Tooltip>
					}

					<Tooltip
						title="Drukuj etykietę"
						aria-label="Drukuj etykietę"
						placement="top"
						arrow
					>
						<PrimaryButton className={classes.downloadLabelButton} onClick={handlePrint(probe.id)}>
							<img src={PrintIcon} alt="Drukuj etykietę" />
						</PrimaryButton>
					</Tooltip>

					<Tooltip
						title="Usuń próbkę"
						aria-label="Usuń próbkę"
						placement="top"
						arrow
					>
						<DeleteButton
							className={classes.deleteButton}
							skipNativeClass={true}
							hint={'Czy na pewno chcesz usunąć tę próbkę?'}
							onClickHandler={() => handleDelete(probe.id)}
						>
							<img src={TrashIcon} alt="Usuń próbkę" />
						</DeleteButton>
					</Tooltip>
					{/*<Button
						className={classes.deleteButton}
						onClick={handleDelete(probe.id)}
					>
						<img src={TrashIcon} alt="Delete" />
					</Button>*/}
				</Box>
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<ProbesTable
				probes={probes}
				renderRow={renderRow}
			/>
			<Box className={classes.printButtons}>
				<PrimaryButton
					onClick={() => setOpenDownloadLabelModal(true)}
					style={{ marginRight: 10 }}
				>
					{t('pages.patient.sets.download')}
				</PrimaryButton>
				<PrimaryButton onClick={() => setOpenFormModal(true)}>
					{t('pages.patient.sets.addNewProbe')}
				</PrimaryButton>
			</Box>

			<FormModal open={openFormModal} onClose={handleClose} selectedSet={set} />
			<DownloadLabelModal
				open={openDownloadLabelModal}
				onClose={() => setOpenDownloadLabelModal(false)}
				defaultProbes={probes}
				printProbes={handlePrint}
			/>
		</>
	);
};

export default Probes;
