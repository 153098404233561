import React from "react";
import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiInputAdornment-root": {
      background: "#F7F8FB",
      height: "100%",
      maxHeight: "max-content",
      borderLeft: "1px solid #A6AEBF",
      "& .MuiTypography-root": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 80,
        height: "100%",
        fontSize: 16,
        color: "#003366",
      },
    },
  },
  label: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#454D5F",
    marginBottom: theme.spacing(1),
  },
  textInput: {
    background: "#FFF",
    borderRadius: 8,
    height: 56,
    "& .MuiInputBase-input": {
      fontFamily: "Lato",
      fontSize: 16,
      color: "#22272F",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A6AEBF",
      borderRadius: 8,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  disabledInput: {
    background: "#DDE3EE",
    "& .MuiInputBase-input": {
      color: "#6C7689",
    },
  },
}));

const TextInput = ({ label, required, gutterBottom = true, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} mb={gutterBottom ? 2 : 0}>
      <Typography className={classes.label}>
        {label}
        {required && <Typography component="span" color="secondary">*</Typography>}
      </Typography>
      <TextField
        className={clsx(classes.textInput, { [classes.disabledInput]: rest.disabled })}
        variant="outlined"
        fullWidth
        {...rest}
      />
    </Box>
  );
};

export default TextInput;
